import React, {Component} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import "./Admin.css"

let ps;

const useStyles = makeStyles(styles);

function AdminN({ ...rest }) {
  var cardViewStyle = ""
  let restProps = rest;
  restProps.handleDrawerToggle = handleDrawerToggle;
  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route path={prop.layout + prop.path} render={(props) => React.createElement(prop.component, restProps)} key={key}/>
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  
  // eslint-disable-next-line
  const [image, setImage] = React.useState(bgImage);
  // eslint-disable-next-line
  const [color, setColor] = React.useState("blue");
  // eslint-disable-next-line
  const [fixedClasses, setFixedClasses] = React.useState("dropdown"); /*TO start with default showing settings pop: change "dropdown" for "dropdown show"*/ 
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  function handleDrawerToggle(){
    return setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  if(window.location.pathname === "/admin/tarjeta/"){
    cardViewStyle="cardViewStyle"
  }
  return (
    <div className={classes.wrapper} id={cardViewStyle}>
      <Sidebar
        routes={routes}
        logoText={"CARNET EMPRESARIAL"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}

export default class Admin extends Component {
  constructor({ ...rest }) {
    super()
    this.state = {
      username: "",
      password: "",
      errors: null,
      flag_on_1: 0

   }
  }

  render(){
    return(
      <AdminN rest={this.props}/>
    );
  }
}
