import React, {Component, Suspense} from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import ProfileTemplate from "views/ProfileTemplate/ProfileTemplate";
import config from 'config';

//Axios HTTP Methods
import axios from 'axios';

import "assets/css/material-dashboard-react.css?v=1.9.0";

import {Auth} from 'aws-amplify';

var AWS = require('aws-sdk');

class App extends Component {
    constructor(props) {
      super(props)
      this.setAWSConfig = this.setAWSConfig.bind(this);
      this.getAWSCreds = this.getAWSCreds.bind(this);
    }

    state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      reloadPending: false,
      productsAndServices: false,
      currentUserTemplate: {
        userURL: "/tarjeta/"
      },
      isViewingUser: false,
      credentials: {
        accessKeyId: "",
        secretAccessKey: "",
        sessionToken: ""
      },
      AWS: {},
      s3AWSBucket: {}
    }

      setAuthStatus = authenticated => {
        this.setState({ isAuthenticated: authenticated});
      }
    
      setUser = user => {
        this.setState({user: user});
      }

      setReloadPending = bool => {
        this.setState({reloadPending: bool});
      }

      setProductsAndServices = bool => {
        this.setState({productsAndServices: bool});
      }

      setCurrentUserTemplate = object => {
        this.setState({currentUserTemplate: object});
      }

      setIsViewingUser = bool => {
        this.setState({isViewingUser: bool});
      }

      setIconColor = color => {
        this.setState({iconColor: color});
      }

    
      async componentDidMount() {
        try{
          const session = await Auth.currentSession();
          this.setAuthStatus(true);
          // console.log("session: ", session);
          const user = await Auth.currentAuthenticatedUser();
          this.setUser(user);
          // console.log("user: ", user);
          axios.defaults.headers.common['IDToken'] = session.getIdToken().getJwtToken();
          this.setAWSConfig();
          let endpoint_url = user.client.endpoint.split("https://");
          endpoint_url = endpoint_url[1]
          const credentials = await this.getAWSCreds(endpoint_url, user.pool.userPoolId, session.getIdToken().getJwtToken());
          let s3AWSBucket = await this.setAWSconfigCredentials(credentials.accessKeyId, credentials.secretAccessKey, credentials.sessionToken);
          this.setState({credentials: credentials, AWS: AWS, s3AWSBucket: s3AWSBucket})
        }catch(error){
          console.log(error);
          this.setAuthStatus(false);
        }
        this.setState({isAuthenticating: false});

      }

      configureAfterLogin = async (session, user) => {
        this.setAWSConfig();
        let endpoint_url = user.client.endpoint.split("https://");
        endpoint_url = endpoint_url[1]
        const credentials = await this.getAWSCreds(endpoint_url, user.pool.userPoolId, session.getIdToken().getJwtToken());
        let s3AWSBucket = await this.setAWSconfigCredentials(credentials.accessKeyId, credentials.secretAccessKey, credentials.sessionToken);
        this.setState({credentials: credentials, AWS: AWS, s3AWSBucket: s3AWSBucket})
      }

      setAWSconfigCredentials = async(accessKeyId, secretAccessKey, sessionToken) => {
        await AWS.config.update({ 
          accessKeyId: accessKeyId, 
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
          region: config.s3.region, 
          dirName: config.s3.dirName });

        var s3AWSBucket = await new AWS.S3({ params: {Bucket: config.s3.bucketName} });
        return s3AWSBucket;
      }

      setAWSConfig(){
        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = 'us-west-2'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:c0bd0e57-43ce-4c2c-b48e-891b1f5fec7e',
            Logins: {
              'cognito-idp.us-west-2.amazonaws.com/us-west-2_P9GZMefqH': ""
            }
        });
      }

      async getAWSCreds(endpoint_url, userPoolId, IDToken) {
        let credentials = this.state.credentials
        AWS.config.credentials.params.Logins[`${endpoint_url}${userPoolId}`] = IDToken;
        
        // Make the call to obtain credentials
        return new Promise((resolve, reject) => { AWS.config.credentials.get(async function(err, file){

          // Credentials will be available when this function is called.
          credentials.accessKeyId = await AWS.config.credentials.accessKeyId;
          credentials.secretAccessKey = await AWS.config.credentials.secretAccessKey;
          credentials.sessionToken = await AWS.config.credentials.sessionToken;
          if (err) {
            console.log("error getting cred: ", err)
            reject(new Error(err))
          } else {
            resolve(credentials)
          }
        });
        }).catch(error => {
          console.log("Error: ",error)
        });
      }


    render() {
        const authProps = {
          isAuthenticated: this.state.isAuthenticated,
          user: this.state.user,
          setAuthStatus: this.setAuthStatus,
          setUser: this.setUser,
          setReloadPending: this.setReloadPending,
          reloadPending: this.state.reloadPending,
          productsAndServices: this.state.productsAndServices,
          setProductsAndServices: this.setProductsAndServices,
          currentUserTemplate: this.state.currentUserTemplate,
          setCurrentUserTemplate: this.setCurrentUserTemplate,
          credentials: this.state.credentials,
          AWS: this.state.AWS,
          s3AWSBucket: this.state.s3AWSBucket,
          configureAfterLogin: this.configureAfterLogin
        }
        const validationProps = {
          iconColor: "black",
          setIconColor: this.setIconColor,
          isViewingUser: this.state.isViewingUser,
          setIsViewingUser: this.setIsViewingUser
        }

        return (
          !this.state.isAuthenticating &&
          <Suspense fallback={<div>Loading...</div>}>

          
          <Router history={this.props.hist} auth={authProps}>
            <Switch>
                <Route path="/admin" render={(props) => <Admin {...props} auth={authProps} validationProps={validationProps} />} />
                <Route path="/admin/tarjeta/?company=:company&id=:id&created=:created" render={(props) => <ProfileTemplate {...props} auth={authProps} />} />
                <Redirect from="/" to="/admin/dashboard" />
            </Switch>
          </Router>
          </Suspense>
        );
      }
}  
export default App;