import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SyncIcon from '@material-ui/icons/Sync';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList(props) {
  const classes = useStyles();

  function handleCardProps(element){
    props.handleCardProps(element.company, element.agent_name, element.agent_id, element.email, element.created_at, element.content);
  }
  function handleEditCardClick(cardInfo){
    props.handleEditCardClick(cardInfo.company, cardInfo.agent_name, cardInfo.agent_link,
      cardInfo.agent_position,
      cardInfo.agent_id, cardInfo.email, 
      cardInfo.created_at, cardInfo.content, 
      cardInfo.active, cardInfo.backgroundImage, 
      cardInfo.social_links);
  }
  function handleDeleteCardClick(cardInfo){
    props.handleDeleteCardClick(cardInfo.company, cardInfo.agent_id, cardInfo.content);
  }
  function handleAddProductsCardClick(cardInfo){
    props.handleAddProductsCardClick(cardInfo.company, cardInfo.agent_id, cardInfo.agent_name, cardInfo.content, JSON.parse(cardInfo.products));
  }

  function handleSocialMediaCardClick(link){
    if(link !== ''){
      let sublink = link.substring(0,4)
      if(sublink !== "http")
          window.open(`https://${link}`, '_blank');
      else
          window.open(link, '_blank');
    } else{
      
    }
  }

  function refreshTable(){
    if(props.refreshTable !== 'undefined'){
      props.refreshTable();
    }
  }

  function validateLinks(facebookLink, instagramLink, twitterLink, linkedInLink){
    let objLinksStyles = {
      facebook: {
        facebookLink: 'facebook.com',
        styleColor: '#808080'
      },
      instagram: {
        instagramLink: 'instagram.com',
        styleColor: '#808080'
      },
      twitter: {
        twitterLink: 'twitter.com',
        styleColor: '#808080'
      },
      linkedIn: {
        linkedInLink: 'linkedin.com',
        styleColor: '#808080'
      },
    }

    if(facebookLink !== ''){
      objLinksStyles.facebook.facebookLink = facebookLink
      objLinksStyles.facebook.styleColor = "#3b5998"
    }
    if(instagramLink !== ''){
      objLinksStyles.instagram.instagramLink = instagramLink
      objLinksStyles.instagram.styleColor = "#bc2a8d"
    }
    if(twitterLink !== ''){
      objLinksStyles.twitter.twitterLink = twitterLink
      objLinksStyles.twitter.styleColor = "#26a7de"
    }
    if(linkedInLink !== ''){
      objLinksStyles.linkedIn.linkedInLink = linkedInLink
      objLinksStyles.linkedIn.styleColor = "#0072b1"
    }
    return objLinksStyles
  }

  const ListDataArray = props.currentCards
  const ListDataTable = ListDataArray.map((element) => {
    let facebookLink = JSON.parse(element.social_links).facebook;
    let instagramLink = JSON.parse(element.social_links).instagram;
    let twitterLink = JSON.parse(element.social_links).twitter;
    let linkedInLink = JSON.parse(element.social_links).linkedin
    let objLinksStyles = validateLinks(facebookLink, instagramLink, twitterLink, linkedInLink)

    let content = JSON.parse(element.content);
    let object = {
      view: ( <IconButton onClick={() => handleCardProps(element)} size="small" aria-label="edit" className={classes.margin}>
      <VisibilityIcon  fontSize="small"/>
      </IconButton>),
      company: element.company,
      agent_name: element.agent_name, 
      email: element.email,
      address: content.address,
      number: content.number,
      created_at: element.created_at,
      qrcode: (<a href={content.qrcode} target="_blank" rel="noopener noreferrer">QR</a>),
      facebook: (<div><FacebookIcon onClick={() => handleSocialMediaCardClick(objLinksStyles.facebook.facebookLink)} style={{ color: objLinksStyles.facebook.styleColor, cursor: "pointer"}}/></div>),
      instagram: (<div><InstagramIcon onClick={() => handleSocialMediaCardClick(objLinksStyles.instagram.instagramLink)} style={{ color: objLinksStyles.instagram.styleColor, cursor: "pointer"}}/></div>),
      twitter: (<div><TwitterIcon onClick={() => handleSocialMediaCardClick(objLinksStyles.twitter.twitterLink)} style={{ color: objLinksStyles.twitter.styleColor, cursor: "pointer"}}/></div>),
      linkedin: (<div><LinkedInIcon onClick={() => handleSocialMediaCardClick(objLinksStyles.linkedIn.linkedInLink)} style={{ color: objLinksStyles.linkedIn.styleColor, cursor: "pointer"}}/></div>),
      products: ( <IconButton onClick={() => handleAddProductsCardClick(element)} style={{backgroundColor: "gray"}} size="small" aria-label="products" className={classes.margin}>
                    <MonetizationOnIcon style={{ color: "#FFD700" }} fontSize="small"/>
                  </IconButton>), 
      edit: ( <IconButton onClick={() => handleEditCardClick(element)} style={{backgroundColor: "green"}} size="small" aria-label="edit" className={classes.margin}>
                <EditIcon style={{ color: "white" }} fontSize="small"/>
              </IconButton>),
      delete: ( <IconButton onClick={() => handleDeleteCardClick(element)} style={{backgroundColor: "red"}} size="small" aria-label="delete" className={classes.margin}>
                  <DeleteIcon style={{ color: "white" }} fontSize="small"/>
                </IconButton>)
      
    }
      return Object.values(object)
  });
  return (
    <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            {props.refreshTable &&
              <IconButton onClick={refreshTable} size="small" aria-label="edit" style={{position: "relative", float: "right", color: "white"}}>
              <SyncIcon />
            </IconButton>
            }
            <h4 className={classes.cardTitleWhite}>Tarjetas Digitales</h4>
            <p className={classes.cardCategoryWhite}>
              Disponibles
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Ver","Compañia", "Nombre", "Email", "Direccion", "Numero", "Fecha de Creacion", "Codigo QR", "Facebook","Instagram","Twitter", "LinkedIn", "Productos", "Editar", "Borrar"]}
              tableData={ListDataTable}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Table on Plain Background
            </h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Country", "City", "Salary"]}
              tableData={[
                ["1", "Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
                ["2", "Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
                ["3", "Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
                [
                  "4",
                  "Philip Chaney",
                  "$38,735",
                  "Korea, South",
                  "Overland Park"
                ],
                [
                  "5",
                  "Doris Greene",
                  "$63,542",
                  "Malawi",
                  "Feldkirchen in Kärnten"
                ],
                ["6", "Mason Porter", "$78,615", "Chile", "Gloucester"]
              ]}
            />
          </CardBody>
        </Card>
      </GridItem> */}
    </GridContainer>
    </div>
  );
}
