
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import AddCircle from "@material-ui/icons/AddCircle";
import Visibility from "@material-ui/icons/Visibility";
import Update from "@material-ui/icons/Update";
import HighlightOff from "@material-ui/icons/HighlightOff";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Login from "views/Login/Login";
import Card from "views/Card/Card"
import Create from "views/Card/Create"
import Update_View from "views/Card/Update"
import Read from "views/Card/Read";
import ProfileTemplate from "views/ProfileTemplate/ProfileTemplate";
import ProductsAndServices from "views/Card/ProductsAndServices";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import WorkIcon from '@material-ui/icons/Work';


const dashboardRoutes = [
  
  { 
    path: "/login",
    name: "Iniciar Sesión",
    icon: Person,
    component: Login,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Inicio",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/create",
    name: "Crear Tarjeta",
    icon: AddCircle,
    component: Create,
    layout: "/admin",
  },
  {
    path: "/read",
    name: "Ver Tarjetas",
    icon: Visibility,
    component: Read,
    layout: "/admin",
  },
  {
    path: "/update",
    name: "Actualizar Tarjeta",
    icon: Update,
    component: Update_View,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/delete",
    name: "Borrar Tarjeta",
    icon: HighlightOff,
    component: Card,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/tarjeta/",
    name: "Perfil",
    icon: AccountBoxIcon,
    component: ProfileTemplate,
    layout: "/admin",
  },
  {
    path: "/servicios/",
    name: "Productos y Servicios",
    icon: WorkIcon,
    component: ProductsAndServices,
    layout: "/admin",
  },
  // {
  //   path: "/agregarproductos",
  //   name: "Agregar Productos",
  //   icon: HighlightOff,
  //   component: AddProducts,
  //   layout: "/admin",
  //   invisible: true
  // },
  


  
  
];

export default dashboardRoutes;
