import React, { Component } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import NewCard from "views/Card/Card"
import { Hidden} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/cardImagesStyles.js";
import config from 'config'
import './ProductsAndServices.css'
import SocialMediaBadges from '../ProfileTemplate/SocialMediaBadges'

const useStyles = makeStyles(styles);

export default class ProductsAndServices extends Component {
  
  
  constructor(props){
      super(props)

      this.state = {
        products: {},
        IDToken: '',
        body: {
          company: "",
          created_at: "",
          agent_id: "",
          agent_name: "",
          email: "",
          content: "",
          qrcode: "",
          address: "",
          number: "",
          image: "",
          socialMediaLinks: {
            facebook: "",
            instagram: "",
            twitter: "",
            linkedIn: "",
            amazon: "",
            mercadoLibre: "",
            ebay: "",
            shopify: ""
          }
        },
        currentUserTemplate: this.props.rest.auth.currentUserTemplate,
        agent_data:{
          qrcode: "",
          address: "",
          number: "",
          image: ""
        },
        classes: useStyles,
        isCreating: false,
        selectedFile: null,
        selectedFileBackground: null,
        InputErrors: {
          company: false,
          email: false,
          agent_name: false,
          address: false,
          number: false
        },
        SnackBar: {
          message: "",
          color: "",
          close: "",
          icon: "",
          place: "",
          open: ""
        },
        id: ""
      }
  }

  async componentDidMount() {
    if(this.props.rest.validationProps.isViewingUser){
      this.props.rest.validationProps.setIsViewingUser(false);
    }
    try{
      var products = JSON.parse(this.state.currentUserTemplate.products)
      let socialMediaLinks = JSON.parse(this.state.currentUserTemplate.social_links)
      var id = this.state.currentUserTemplate.agent_id
      this.setState({products: products, id: id, socialMediaLinks: socialMediaLinks})
    }catch(error){
      console.log(error);
      this.setState({products: products})
    }
    JSON.stringify(this.state.body.content)
  }

  render() {
    var listItems = ""

    if(this.state.products !== undefined){
      var elements = this.state.products;
      var arr  = []
      var keys = Object.keys(elements);
      for(var i=0,n=keys.length;i<n;i++){
        var key  = keys[i];
        arr[i] = elements[key];
      }
      
      listItems = arr.map((element) => {
        let newEncode = element.cardImage.split(`${config.app.static_url}/static/media/users/`);
        let encodedURL_Image = `${config.app.static_url}/static/media/users/` + encodeURI(newEncode[1]);
        let title = element.cardName
        let body = element.cardDescription
        if (title === ""){
          title = " "
        }
        if(body === ""){
          body = " "
        }
        return (
          <div class="row">
            <NewCard 
            handleCardProps={this.handleCardProps}
            company={element.cardTitle}
            title={title} 
            body={body}
            content={JSON.stringify({image: encodedURL_Image})}
            buttonText=""
            handleEditCardClick={this.handleEditCardClick}
            handleDeleteCardClick={this.handleDeleteCardClick}
            />
          </div>
        )
      });
    }
    if(this.state.currentUserTemplate.length === 0 || this.state.currentUserTemplate === undefined){
      return <Redirect to='/admin'/>
    }
    // let hideProducts = false
    let amazon = this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.amazon : "";
    let mercadoLibre = this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.mercadolibre : "";
    let ebay = this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.ebay : "";
    let shopify = this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.shopify : "";
    if(listItems.length === 0){
      // hideProducts = true
    }

    return(
      <div>
        <Hidden>
        <GridContainer> 
              <GridItem xs={12} sm={12} md={12}>
                  {/* <CardHeader color="primary"> */}
                      {/* { amazon === "" && mercadoLibre === "" && ebay === "" && shopify === "" &&
                        <h4 className={this.state.classes.cardTitleWhite}>eCommerce</h4>} */}

                      {/* { !hideProducts &&
                        <h5 className={this.state.classes.cardTitleWhite}>Productos y Servicios: {this.props.rest.auth.currentUserTemplate.agent_name}</h5>
                        }

                      { hideProducts &&
                        <h5 className={this.state.classes.cardTitleWhite}>{this.props.rest.auth.currentUserTemplate.agent_name}</h5>
                        } */}
                      <center>
                        { (amazon !== "" || mercadoLibre !== "" || ebay !== "" || shopify !== "") &&
                          <h4 className={`${this.state.classes.cardTitleWhite} "parentDiv"`} style={{fontWeight: "bold"}}>Métodos de pago</h4>}
                          
                        <SocialMediaBadges 
                          amazon={this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.amazon : ""}
                          mercadolibre={this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.mercadolibre : ""}
                          ebay={this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.ebay : ""}
                          shopify={this.state.socialMediaLinks !== undefined ? this.state.socialMediaLinks.shopify : ""}
                          handleBadgeClick={this.handleBadgeClick}/>
                      </center>
                  {/* </CardHeader> */}
                  <center>
                    <div class="rows">
                        {listItems}
                    </div>
                  </center>
              </GridItem>
            </GridContainer>
        </Hidden>
      </div>
    )
  }
}