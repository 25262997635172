/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Amplify from 'aws-amplify';
import config from './config';
import App from './App'
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';

import "assets/css/material-dashboard-react.css?v=1.9.0";

//Toast notifications
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory();

//Needed for Toast to work
toast.configure();

// Amplify Configure
Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

let app = document.getElementById('root');
if (app) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

	if (path) {
    //window.location = `http://localhost:3000${path}`
    history.replace(path);
	}

    // 2. Render our app
	render(<App hist={history}/>, app);
}