
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessIcon from '@material-ui/icons/Business';
import CallIcon from '@material-ui/icons/Call';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import { Hidden} from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import NewCard from "views/Card/Card"
import DropdownMenu from "components/DropdownMenu/DropdownMenu.js";

//Image ICONS
import style1b1 from "../../assets/img/llamame.png"
import style1b2 from "../../assets/img/whatsapp.png"
import style1b3 from "../../assets/img/sms.png"
import style1b4 from "../../assets/img/direccion.png"
import style1b5 from "../../assets/img/add1.png"

import style2b1 from "../../assets/img/llamar1.png"
import style2b2 from "../../assets/img/whatsapp1.png"
import style2b3 from "../../assets/img/email1.png"
import style2b4 from "../../assets/img/direccion1.png"
import style2b5 from "../../assets/img/add2.png"

import style3b1 from "../../assets/img/llamar2.png"
import style3b2 from "../../assets/img/whatsapp2.png"
import style3b3 from "../../assets/img/email2.png"
import style3b4 from "../../assets/img/direccion2.png"
import style3b5 from "../../assets/img/add3.png"

import style4b1 from "../../assets/img/llamar3.png"
import style4b2 from "../../assets/img/whatsapp3.png"
import style4b3 from "../../assets/img/email3.png"
import style4b4 from "../../assets/img/direccion3.png"
import style4b5 from "../../assets/img/add4.png"

//Template Background Images
import bg1 from 'assets/img/background1.png'
import bg2 from 'assets/img/background2.png'
import bg3 from 'assets/img/background3.png'
import bg4 from 'assets/img/background4.png'
import bg5 from 'assets/img/background5.jpg'
import bg6 from 'assets/img/background6.jpg'
import bg7 from 'assets/img/background7.jpg'

//Social media icons
import Google_maps from '../../assets/img/location.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Amazon from '../../assets/img/amazonicon.png'
import EbayIcon from '../../assets/img/ebayicon.png'
import ShopifyIcon from '../../assets/img/shopifyicon.png'
import MercadolibreIcon from '../../assets/img/mercadolibreicon.png'
import Otro from '@material-ui/icons/Add';
// import LinkDeAgente from '@material-ui/icons/CoPresent';

import { Auth } from "aws-amplify";
import FormErrors from "views/Login/FormErrors";
import loading from 'assets/img/loading.gif'
import config from 'config'
import './Create.css'

//Toast notifications
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Axios HTTP Methods
import axios from 'axios';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// var AWS = require('aws-sdk');
// var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName} } );

const useStyles = makeStyles(styles);

class Create extends Component {
  
  
    constructor(props){
        super(props)
        // Bind "this" keyword to functions
        this.handleImageUpload_submitHandler = this.handleImageUpload_submitHandler.bind(this);
        this.handleImagePreview = this.handleImagePreview.bind(this);
        this.handleDataValidation = this.handleDataValidation.bind(this);
        this.handleQR_Create = this.handleQR_Create.bind(this);
        this.handleQR_Create2 = this.handleQR_Create2.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.handleQR_UploadS3 = this.handleQR_UploadS3.bind(this);
  
        this.state = {
          IDToken: '',
          body: {
            company: "",
            created_at: "",
            agent_id: "",
            agent_name: "",
            agent_position: "",
            email: "",
            content: "",
            qrcode: "",
            address: "",
            number: "",
            areaCode: "52",
            image: "",
            google_maps: "",
            facebook: "",
            instagram: "",
            twitter: "",
            linkedin: "",
            mercadolibre: "",
            amazon: "",
            shopify: "",
            ebay: "",
            otro: "",
            agent_link: "",
          },
          agent_data:{
            qrcode: "",
            address: "",
            number: "",
            image: ""
          },
          classes: useStyles,
          isCreating: false,
          selectedFile: null,
          selectedFileBackground: null,
          InputErrors: {
            company: false,
            email: false,
            agent_name: false,
            agent_position: false,
            address: false,
            number: false,
            areaCode: false,
            google_maps: false
          },
          SnackBar: {
            message: "",
            color: "",
            close: "",
            icon: "",
            place: "",
            open: ""
          },
          status: "",
          PreviewBgImage: bg1,
          styleb1: style1b1,
          styleb2: style1b2,
          styleb3: style1b3,
          styleb4: style1b4,
          styleb5: style1b5,
          fontColor: "black",
          credentials: this.props.rest.auth.credentials
        }
    }

    // Sets JWT Token in React's State var "IDToken"
    async componentDidMount() {
      if(this.props.rest.validationProps.isViewingUser){
        this.props.rest.validationProps.setIsViewingUser(false);
      }
        try{
          // await this.setAWSconfig();
          let session = await Auth.currentSession();
          const user = await Auth.currentAuthenticatedUser();
          await this.props.rest.auth.configureAfterLogin(session, user);
          let jwt = session.getIdToken();
          axios.defaults.headers.common['IDToken'] = session.getIdToken().getJwtToken();
          this.setState({IDToken: jwt.getJwtToken()});
        }catch(error){
          console.log(error);
        }
        JSON.stringify(this.state.body.content)
    }

    // setAWSconfig = async() => {
    //   // AWS.config.update({ 
    //   //   accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
    //   //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey,
    //   //   sessionToken: this.props.rest.auth.credentials.sessionToken,
    //   //   region: config.s3.region, 
    //   //   dirName: config.s3.dirName });

    //   // s3AWSBucket.config.update({ 
    //   //   accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
    //   //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey,
    //   //   sessionToken: this.props.rest.auth.credentials.sessionToken,
    //   //   // region: config.s3.region, 
    //   //   dirName: config.s3.dirName });
    // }

    // Simple Method to display Success notifications 
    notifyGood = () =>{
        toast.success('Tarjeta creada con exito!', {position: toast.POSITION.TOP_CENTER});
      }
  
    // Simple Method to display Error notifications 
    notifyBad = (message) =>{
    toast.error(message, {position: toast.POSITION.TOP_CENTER});
    }

    // Method to obtain jwt Token and apply string token to React's state
    getTokens = () => {
      try{
        Auth.currentSession().then(res=>{
          let accessToken = res.getAccessToken();
          let jwt = accessToken.getJwtToken();
          this.setState({IDToken: jwt});
        })
      } catch(error){
        console.log(error);
      }
    };

    submitHandler = () =>{
      this.setState({status: "Creando tarjeta digital..."});

      //Setup design en personalization for user's template
      let backgroundImage = {
        backgroundImage: this.state.PreviewBgImage,
        styleb1: this.state.styleb1,
        styleb2: this.state.styleb2,
        styleb3: this.state.styleb3,
        styleb4: this.state.styleb4,
        styleb5: this.state.styleb5,
        fontColor: this.state.fontColor
      }
      backgroundImage = JSON.stringify(backgroundImage);

      //Setup Social media links and others for user's
      let social_links = {
        google_maps: this.state.body.google_maps,
        facebook: this.state.body.facebook,
        instagram: this.state.body.instagram,
        twitter: this.state.body.twitter,
        linkedin: this.state.body.linkedin,
        mercadolibre: this.state.body.mercadolibre,
        amazon: this.state.body.amazon,
        shopify: this.state.body.shopify,
        ebay: this.state.body.ebay,
        otro: this.state.body.otro
      }
      social_links = JSON.stringify(social_links);

      // Setting object values to send in request
        let object = {
            IDToken: this.state.IDToken,
            body: {
              company: this.state.body.company,
              created_at: this.state.body.created_at,
              agent_id: this.state.body.agent_id,
              agent_link: this.state.body.agent_link,
              agent_name: this.state.body.agent_name,
              agent_position: this.state.body.agent_position,
              email: this.state.body.email,
              content: this.state.body.content,
              username: this.props.rest.auth.user.username,
              active: true,
              backgroundImage: backgroundImage,
              social_links: social_links,
              products: "{}"
            }
        }
      // Setting adent_data values to replace in content
        let agent_data = {
          // qrcode: this.state.body.qrcode,
          qrcode: `${config.app.static_url}/static/media/users/qrcodes/${this.state.body.company}_${this.state.body.agent_name}_${this.state.body.agent_id}.jpeg`,
          image: this.state.body.image? this.state.body.image : `${config.app.static_url}/static/media/users/NoPic.png`,
          address: this.state.body.address,
          number: `${this.state.body.areaCode} ${this.state.body.number}`
        };
      
      // Stringify JSON object to add in object.content
        let content = JSON.stringify(agent_data);
        object.body.content = content;
        
      // POST Data
        axios.defaults.headers.common['IDToken'] = this.state.IDToken;
        axios.post(config.api.invokeUrl, object)
        .then(response => {
          // On success response: Kills loading gif, logs response and validates response message
          console.log(response.data.url)
          this.handleQR_Create2(response.data.url);
          // this.setState({isCreating: false});
          // this.setState({selectedFile: null});
          // this.props.rest.auth.setReloadPending(true);
          // localStorage.removeItem('currentCards')
          // agent_data.image = "";
          // agent_data.number = "";
          // this.setState({agent_data: agent_data});
          
          let statusCode = response.data.statusCode;
          if (statusCode !== 400){
            this.notifyGood();
            // this.resetStateBdoy();
          }else{
            if(response.data.message === 'The ID Token does not containt user_id'){
              this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
            }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){
              this.notifyBad('Favor de llenar todos los campos.');
            }
            else{
              this.notifyBad('Una tarjeta con ese correo ya existe.');
            }
            
          }
        })
        .catch(error =>{
          // On error logs error and notify the user
          this.setState({isCreating: false});
          console.log(error);
          this.notifyBad(error);
        });
    };

    resetStateBdoy = () =>{
      // Clears body state
      let body = this.state.body
      body.company = "";
      body.email = "";
      body.agent_id = "";
      body.agent_name = "";
      body.agent_position = "";
      body.content = "";
      body.qrcode = "";
      body.address = "";
      body.number = "";
      body.areaCode = "52";
      body.image = "";
      body.google_maps = "";
      body.facebook = "";
      body.instagram = "";
      body.twitter = "";
      body.linkedin = "";
      body.mercadolibre = "";
      body.amazon = "";
      body.shopify = "";
      body.ebay = "";
      body.otro = "";
      body.agent_link = "";
      this.setState({body: body});
    }

    handleDataValidation() {
      let error = false;
      let body = this.state.body;
      let InputErrors = this.state.InputErrors;

      if(body.company === ''){
        error = true;
        InputErrors.company = true
      } 
      if(body.agent_name === ''){
        error = true;
        InputErrors.agent_name = true
      }
      if(body.agent_position === ''){
        error = true;
        InputErrors.agent_position = true
      } 
      if(body.email === '' || !document.getElementById('email').validity.valid){
        error = true;
        InputErrors.email = true
      } 
      if(body.address === ''){
        error = true;
        InputErrors.address = true
      } 
      if(body.number === ''){
        error = true;
        InputErrors.number = true
      }
      if(body.areaCode === ''){
        error = true;
        InputErrors.areaCode = true
      }
      if(body.google_maps === ''){
        error = true;
        InputErrors.google_maps = true
      }
      if(error){
        this.notifyBad('Favor de llenar los campos correspondientes.');
        this.setState({InputErrors: InputErrors});
        return false;
      }else{
        InputErrors.company = false
        InputErrors.agent_name = false
        InputErrors.agent_position = false
        InputErrors.email = false
        InputErrors.address = false
        InputErrors.number = false
        InputErrors.areaCode = false
        InputErrors.google_maps = false
      }
      return true;
    }

    handleImageUpload_submitHandler(e) {
      e.preventDefault();
      // Kills submit on error found
      if(!this.handleDataValidation()) return 0;

      this.setState({isCreating: true, status: "Guardando foto de perfil..."});

      //Delete after test
      // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName} } );
      // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName, accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
      //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey, sessionToken: this.props.rest.auth.credentials.sessionToken} } );
      //   s3AWSBucket.config.accessKeyId = this.state.credentials.accessKeyId;
      //   s3AWSBucket.config.secretAccessKey = this.state.credentials.secretAccessKey;
      //   s3AWSBucket.config.sessionToken = this.state.credentials.sessionToken;
      // var s3AWSBucket = this.props.rest.auth.s3AWSBucket;
        // console.log("a2", s3AWSBucket);
      // Gets body from state
      let config_S3 = config.s3;
      config_S3.accessKeyId = this.props.rest.auth.credentials.accessKeyId;
      config_S3.secretAccessKey = this.props.rest.auth.credentials.secretAccessKey;
      delete config_S3.prm1
      delete config_S3.prm2
      config_S3.key = `static/media/users/${this.state.body.company}_${this.state.body.agent_name}.jpeg`


      this.getBase64(this.state.selectedFile, (result) => {
        let idCardBase64 = result;

        this.handleProfileImage_UploadS3(idCardBase64, (error) => {
            if(error){
              this.setState({isCreating: false});
              // let credentials = {
              //   accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
              //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey,
              //   sessionToken: this.props.rest.auth.credentials.sessionToken,
              //   region: config.s3.region
              // }
              // console.log("a", s3AWSBucket);
              this.notifyBad("No se encuentra ninguna imagen, favor de seleccionar una");
            } else {
                if(this.state.selectedFileBackground !== null){
                  let idCardBase64 = '';
                  this.getBase64(this.state.selectedFileBackground, (result) => {
                  idCardBase64 = result;
      
                  this.handleBackgroundImage_UploadS3(idCardBase64, (result1) => {
                    //this.handleQR_Create();
                    this.submitHandler();
                  });
                });
                } else {
                  // this.handleQR_Create();
                  this.submitHandler();
                }
            }
        });
      });

      // Tries to upload image to S3 Bucket
      // ReactS3.uploadFile(this.state.selectedFile, config_S3)
      // .then((response) =>{
      //   // On Success updates state image and submits information
      //   body.image = response.location
      //   this.setState({body: body})

      //   if(this.state.selectedFileBackground !== null){
      //       let idCardBase64 = '';
      //       this.getBase64(this.state.selectedFileBackground, (result) => {
      //       idCardBase64 = result;

      //       this.handleBackgroundImage_UploadS3(idCardBase64, (result1) => {
      //         this.handleQR_Create();
      //       });
      //   });
      //   } else {
      //     this.handleQR_Create();
      //   }
        
      // })
      // .catch((error) => {
      //   // On Error notify the user
      //   console.log(error);
      //   this.setState({isCreating: false});
      //   this.notifyBad("No se encuentra ninguna imagen, favor de seleccionar una");
      // })
    }

    handleImagePreview(e){
      let agent_data = this.state.agent_data;
      // Retrieves the selected Image and shows it to user
      
      //const preview = document.querySelector('#preview');
      const file = URL.createObjectURL(e.target.files[0])
      //preview.src = file;
      agent_data.image = file;
      console.log(agent_data)
      this.setState({selectedFile: e.target.files[0], agent_data: agent_data});
    }

    handleBackgroundImagePreview = (e) =>{
      // Retrieves the selected Image and shows it to user
      const file = URL.createObjectURL(e.target.files[0])
      this.setState({selectedFileBackground: e.target.files[0], PreviewBgImage: file})

    }

    getBase64(file, cb) {
      if(file === null){
        return cb('NoPic')
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
          cb(reader.result)
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
      };
    }

    
    handleNumberInputs_StateAndErrors = (e) => {
      const onlyNumbersPattern = /^\d*(?:[0-9]\d*)?$/;
      // Gets the state attribute values
      var InputErrors = this.state.InputErrors;
      var body = this.state.body;
      var agent_data = this.state.agent_data;
      var SnackBar = this.state.SnackBar
      

      // Gets the event id name
      const name = e.target.id;

      if(!(e.target.value.length <=15)) return 0;
      // If the current value passes the validity pattern test then apply that to state
      if (onlyNumbersPattern.test(e.target.value)) {
        InputErrors[name] = false;
        body[name] = e.target.value;
        SnackBar.open = false;
        // Checks number for preview purposes
        if(name === 'number'){
          agent_data.number = e.target.value
          this.setState({agent_data: agent_data});
        }
        this.setState({InputErrors: InputErrors, body: body});
      }
      // Else apply errors
      else{
        SnackBar.message = "Porfavor ingrese un numero."
        SnackBar.open = true;
        SnackBar.color = "warning"
        InputErrors[name] = true;
        this.setState({InputErrors});
      }
    };

    handleTextInputs_State = (e) => {
      // Gets event's name, state attribute body and state's errors
      let name = e.target.id;
      let body = this.state.body;
      let InputErrors = this.state.InputErrors

      function containsInvalidChars(value) {
        const validChars = /^[0-9a-zA-Z$\-._+!*'(),\s\u00C0-\u024F]+$/;
        return !validChars.test(value);
      }

      if(name === "company"){
        // if its valid
        if(containsInvalidChars(e.target.value)) return 0
      }

      //Length validation
      if(!(e.target.value.length <=114)) return 0;
      // Updates state attribute where events name with events value and clears error
      InputErrors[name] = false;
      body[name] = e.target.value;
      this.setState({body: body, InputErrors: InputErrors});
    };

    handleLinkInputs_State = (e) => {
      // Gets event's name, state attribute body and state's errors
      let name = e.target.id;
      let body = this.state.body;
      let InputErrors = this.state.InputErrors
      //Length validation
      if(!(e.target.value.length <=500)) return 0;
      // Updates state attribute where events name with events value and clears error
      InputErrors[name] = false;
      body[name] = e.target.value;
      this.setState({body: body, InputErrors: InputErrors});
    };

    async handleQR_Create() {
      this.setState({status: "Creando Codigo QR..."});
      let agent_data = this.state.agent_data
      let object = {body: {}}
      let params = config.qr_api.params;

      let vcardText = 'BEGIN:VCARD\n' +
      'VERSION:3.0\n' +
      `N;CHARSET=iso-8859-1:;${this.state.body.agent_name};;\n` +
      `FN;CHARSET=iso-8859-1:${this.state.body.agent_name}\n` +
      `ORG;CHARSET=iso-8859-1:${this.state.body.company}\n` +
      `TITLE;CHARSET=iso-8859-1:${this.state.body.agent_position}\n` +
      `TEL;TYPE=CELL:${this.state.body.areaCode}${this.state.body.number}\n` +
      `ADR;CHARSET=iso-8859-1;TYPE=WORK:${this.state.body.address}\n` +
      `EMAIL;TYPE=WORK:${this.state.body.email}\n` +
      // `EMAIL;TYPE=X-Website:${this.state.body.email}\n` +
      'END:VCARD'

      params.text = encodeURIComponent(vcardText);

      object.body = params
      axios.defaults.headers.common['IDToken'] = this.state.IDToken;
      axios.post(config.qr_api.invokeUrl, object)
        .then(response => {
          this.setState({status: "Codigo QR Creado..."});
          // On success response:
          agent_data.qrcode = `data:image/jpeg;base64, ${response.data.qr_image}`
          this.setState({agent_data: agent_data})
          this.handleQR_UploadS3(agent_data.qrcode)

        })
        .catch(error =>{
          // On error logs error and notify the user
          this.setState({isCreating: false});
          console.log(error);
          this.notifyBad(error);
        })
    }

    async handleQR_Create2(URL) {
      this.setState({status: "Creando Codigo QR..."});
      let card_url = `${config.app.url}/admin/tarjeta/${encodeURI(URL)}`
      let qr_base64 = ''
      let object = {body: {}}
      let params = config.qr_api.params;

      params.text = encodeURI(card_url);
      console.log("qr param: ", params.text)

      object.body = params
      axios.defaults.headers.common['IDToken'] = this.state.IDToken;
      axios.post(config.qr_api.invokeUrl, object)
        .then(response => {
          this.setState({status: "Codigo QR Creado..."});
          // On success response:
          qr_base64 = `data:image/jpeg;base64, ${response.data.qr_image}`
          // this.setState({agent_data: agent_data})
          this.handleQR_UploadS3(qr_base64)

        })
        .catch(error =>{
          // On error logs error and notify the user
          this.setState({isCreating: false});
          console.log(error);
          this.notifyBad(error);
        })
    }

    async handleBackgroundImage_UploadS3(base64StringImage, cb) {
      this.setState({status: "Guardando Imagen de Fondo..."});
      // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName} } );
      // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName, accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
      //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey, sessionToken: this.props.rest.auth.credentials.sessionToken} } );
      //   s3AWSBucket.config.accessKeyId = this.state.credentials.accessKeyId;
      //   s3AWSBucket.config.secretAccessKey = this.state.credentials.secretAccessKey;
      //   s3AWSBucket.config.sessionToken = this.state.credentials.sessionToken;
      var s3AWSBucket = this.props.rest.auth.s3AWSBucket;
      let imageType = base64StringImage.substring(11,14);
      
      if(imageType === "png"){
        imageType = "png"
      } else {
        imageType = "jpeg"
      }
      let PreviewBgImage = this.state.PreviewBgImage;
      let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
      var data = {
        Key: `static/media/users/${this.state.body.company}_${this.state.body.agent_name}_${this.state.body.agent_id}_bgImage.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
      };
      s3AWSBucket.putObject(data, function(err, data){
          if (err) { 
            // console.log(err);
            console.log('Hubo un Error al subir la imagen de fondo: ', data);
          } else {
            console.log('Imagen subida con exito.');
            
          }
          cb();
      });
      PreviewBgImage = `${config.app.static_url}/static/media/users/${this.state.body.company}_${this.state.body.agent_name}_${this.state.body.agent_id}_bgImage.${imageType}`
      this.setState({PreviewBgImage: PreviewBgImage})
  }

  async handleProfileImage_UploadS3(base64StringImage, cb) {
    // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName, accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
    //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey, sessionToken: this.props.rest.auth.credentials.sessionToken} } );
    //   s3AWSBucket.config.accessKeyId = this.state.credentials.accessKeyId;
    //   s3AWSBucket.config.secretAccessKey = this.state.credentials.secretAccessKey;
    //   s3AWSBucket.config.sessionToken = this.state.credentials.sessionToken;
    var s3AWSBucket = this.props.rest.auth.s3AWSBucket;
    // Gets body from state
    var body = {...this.state.body}
    let error = false;

    if(base64StringImage === 'NoPic'){
      body.image = `${config.app.static_url}/static/media/users/NoPic.png`
      cb(error);
    } else{
      let imageType = base64StringImage.substring(11,14);
    
      if(imageType === "png"){
        imageType = "png"
      } else {
        imageType = "jpeg"
      }
      let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
      var data = {
        Key: `static/media/users/${this.state.body.company}_${this.state.body.agent_name}.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
      };
      // console.log("My type: ", typeof(s3AWSBucket), s3AWSBucket)
      s3AWSBucket.putObject(data, function(err, data){
          if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen de perfil: ', data);
            error = true
          } else {
            console.log('Imagen subida con exito.');
            error = false
            
          }
          cb(error);
      });
      body.image = `${config.app.static_url}/static/media/users/${this.state.body.company}_${this.state.body.agent_name}.${imageType}`
    }

    this.setState({body: body})
  }

    async handleQR_UploadS3(base64StringImage) {
        this.setState({status: "Guardando QR..."});
        // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName} } );
        // var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName, accessKeyId: this.props.rest.auth.credentials.accessKeyId, 
        //   secretAccessKey: this.props.rest.auth.credentials.secretAccessKey, sessionToken: this.props.rest.auth.credentials.sessionToken} } );
        // s3AWSBucket.config.accessKeyId = this.state.credentials.accessKeyId;
        // s3AWSBucket.config.secretAccessKey = this.state.credentials.secretAccessKey;
        // s3AWSBucket.config.sessionToken = this.state.credentials.sessionToken;
        var s3AWSBucket = this.props.rest.auth.s3AWSBucket;
        let body = this.state.body;
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
          Key: `static/media/users/qrcodes/${this.state.body.company}_${this.state.body.agent_name}_${this.state.body.agent_id}.jpeg`, 
          Body: buf,
          ContentEncoding: 'base64',
          ContentType: 'image/jpeg'
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
              console.log(err);
              console.log('Hubo un Error al subir el QR: ', data);
            } else {
              console.log('Imagen QR creado con exito.');
              
            }
        });
        body.qrcode = `${config.app.static_url}/static/media/users/qrcodes/${this.state.body.company}_${this.state.body.agent_name}_${this.state.body.agent_id}.jpeg`
        this.setState({body: body})
        this.setState({isCreating: false});
        this.setState({selectedFile: null});
        this.props.rest.auth.setReloadPending(true);
        localStorage.removeItem('currentCards')
        
        // this.submitHandler();
    }

    handleCardProps = (mockdata) =>{

    };

    handleBgImageClick = (event) =>{
      this.setState({selectedFileBackground: null, PreviewBgImage: event.target.id})
    }

    retButtonStyle = (style) => {
      let b1, b2, b3, b4, b5, fontColor = "";
      if(style === "style1"){
        b1 = style1b1
        b2 = style1b2
        b3 = style1b3
        b4 = style1b4
        b5 = style1b5
        fontColor = "white"
      } else if(style === "style2"){
        b1 = style2b1
        b2 = style2b2
        b3 = style2b3
        b4 = style2b4
        b5 = style2b5
        fontColor = "black"
      } else if(style === "style3"){
        b1 = style3b1
        b2 = style3b2
        b3 = style3b3
        b4 = style3b4
        b5 = style3b5
        fontColor = "white"
      } else if(style === "style4"){
        b1 = style4b1
        b2 = style4b2
        b3 = style4b3
        b4 = style4b4
        b5 = style4b5
        fontColor = "black"
      }
      return this.setState({styleb1: b1, styleb2: b2, styleb3: b3, styleb4: b4, styleb5: b5, fontColor: fontColor})
    }


    render() {
      console.log(this.state.body)
        // Renders Loading gif
        if(this.state.isCreating){
        return (
          <center>
            <Hidden mdUp>
              <img src={loading} alt="loading..." width="80%"  height="80%"/>
              <h2>{this.state.status}</h2>
            </Hidden>
            <Hidden mdDown>
              <img src={loading} alt="loading..."/>
              <h1>{this.state.status}</h1>
            </Hidden>
          </center>
          
        )
        }

        // Redirect if not logged in
        if (this.props.rest.auth.isAuthenticated === false) {
            return <Redirect to='/'/>
        }

        // Default render
        return (
        <div class="rows">
          <Snackbar place="tc" open={this.state.SnackBar.open} color={this.state.SnackBar.color} message={this.state.SnackBar.message}/>
          
            <GridContainer > 
            <GridItem xs={12} sm={7} md={9}>
                <Card>
                <CardHeader color="primary">
                    <h4 className={this.state.classes.cardTitleWhite}>Crear Tarjeta</h4>
                    <p className={this.state.classes.cardCategoryWhite}>Suba una foto de perfil e ingrese los datos correspondientes</p>
                </CardHeader>
                <CardBody>
                <FormErrors formerrors={this.state.errors} />
                <form>
                 <GridContainer> {/*First Row*/}
                    <GridItem xs={12} sm={1} md={6}>
                      <div class="row"><strong>Foto de perfil:</strong> <input onChange={this.handleImagePreview} type="file" accept="image/png,image/gif,image/jpeg"></input></div>
                    </GridItem>
                  </GridContainer>

                    <GridContainer> {/*Second Row*/}
                      <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                          id="company"
                          labelText="Compañia"
                          error={this.state.InputErrors.company}
                          autoComplete="HelloWorld"
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleTextInputs_State,
                            value: this.state.body.company,
                            startAdornment: (
                              <InputAdornment position="start">
                                <BusinessIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                          id="agent_name"
                          labelText="Nombre"
                          error={this.state.InputErrors.agent_name}
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            onChange: this.handleTextInputs_State,
                            value: this.state.body.agent_name,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                          id="agent_position"
                          labelText="Posición"
                          error={this.state.InputErrors.agent_position}
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            onChange: this.handleTextInputs_State,
                            value: this.state.body.agent_position,
                            startAdornment: (
                              <InputAdornment position="start">
                                <WorkIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                    </GridContainer>

                    <GridContainer> {/*Third Row*/}
                      <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                          id="email"
                          labelText="Email"
                          error={this.state.InputErrors.email}
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleTextInputs_State,
                            type: "email",
                            value: this.state.body.email,
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                          id="address"
                          labelText="Direccion"
                          error={this.state.InputErrors.address}
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            onChange: this.handleTextInputs_State,
                            value: this.state.body.address,
                            startAdornment: (
                              <InputAdornment position="start">
                                <HomeIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                          <CustomInput
                          id="areaCode"
                          labelText="Código de área"
                          error={this.state.InputErrors.areaCode}
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            pattern: "?+(?:[.,]+)?",
                            onChange: this.handleNumberInputs_StateAndErrors,
                            value: this.state.body.areaCode,
                            title: "Debe contener solo numeros.",
                            startAdornment: (
                              <InputAdornment position="start">
                                <AddIcon/>
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                          id="number"
                          labelText="Numero celular"
                          error={this.state.InputErrors.number}
                          formControlProps={{
                              fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            pattern: "?+(?:[.,]+)?",
                            onChange: this.handleNumberInputs_StateAndErrors,
                            value: this.state.body.number,
                            title: "Debe contener solo numeros.",
                            startAdornment: (
                              <InputAdornment position="start">
                                <CallIcon/>
                              </InputAdornment>
                            ),
                          }}
                          />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                            id="google_maps"
                            labelText="Google Maps Link"
                            error={this.state.InputErrors.google_maps}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              pattern: "?+(?:[.,]+)?",
                              onChange: this.handleTextInputs_State,
                              value: this.state.body.google_maps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div>
                                    <a href="https://www.google.com.mx/maps" target="_blank" rel="noopener noreferrer">
                                      <img src={Google_maps}  width="24px" height="24px" alt='maps'/>
                                    </a>
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>{/*4th Row*/}
                      <GridItem xs={12} sm={12} md={12}>
                        <br></br>
                        <p style={{fontWeight: "bold"}} >Links externos</p>
                        
                      </GridItem>
                    </GridContainer>

                    <GridContainer>{/*5th Row*/}
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            id="facebook"
                            labelText="Facebook"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              onChange: this.handleLinkInputs_State,
                              value: this.state.body.facebook,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div>
                                    <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                      <FacebookIcon style={{ color: "#3b5998"}}/>
                                    </a>
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            />
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            id="instagram"
                            labelText="Instagram"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              onChange: this.handleTextInputs_State,
                              value: this.state.body.instagram,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div>
                                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                      <InstagramIcon style={{ color: "#bc2a8d"}}/>
                                    </a>
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            />
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            id="twitter"
                            labelText="Twitter"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              onChange: this.handleTextInputs_State,
                              value: this.state.body.twitter,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div>
                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                      <TwitterIcon style={{ color: "#26a7de"}}/>
                                    </a>
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            />
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            id="linkedin"
                            labelText="LinkedIn"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              onChange: this.handleTextInputs_State,
                              value: this.state.body.linkedin,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div>
                                    <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                      <LinkedInIcon style={{ color: "#0072b1"}}/>
                                    </a>
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>{/*6th Row*/}
                      <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                              id="mercadolibre"
                              labelText="mercadolibre"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                onChange: this.handleTextInputs_State,
                                value: this.state.body.mercadolibre,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div>
                                      <a href="https://www.mercadolibre.com.mx/#from=homecom" target="_blank" rel="noopener noreferrer">
                                        <img src={MercadolibreIcon}  width="24px" height="24px" alt='Mercado Libre'/>
                                      </a>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                              />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                              id="amazon"
                              labelText="amazon"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                onChange: this.handleTextInputs_State,
                                value: this.state.body.amazon,
                                startAdornment: (
                                  
                                  <InputAdornment position="start">
                                    <div>
                                      <a href="https://www.amazon.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={Amazon}  width="24px" height="24px" alt='Amazon'/>
                                      </a>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                              />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                              id="shopify"
                              labelText="shopify"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                onChange: this.handleTextInputs_State,
                                value: this.state.body.shopify,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div>
                                      <a href="https://www.shopify.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={ShopifyIcon}  width="24px" height="24px" alt='Shopify'/>
                                      </a>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                              />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                              id="ebay"
                              labelText="ebay"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                onChange: this.handleTextInputs_State,
                                value: this.state.body.ebay,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div>
                                      <a href="https://www.ebay.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={EbayIcon}  width="24px" height="24px" alt='Ebay'/>
                                      </a>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                              />
                        </GridItem>
                        <GridItem xs={12} sm={2.4} md={2.4}>
                          <CustomInput
                              id="otro"
                              labelText="otro"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                type: "text",
                                onChange: this.handleTextInputs_State,
                                value: this.state.body.otro,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div><Otro style={{ color: "green"}}/></div>
                                  </InputAdornment>
                                ),
                              }}
                              />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>{/*7th Row*/}
                      <GridItem xs={12} sm={12} md={12}>
                        <br></br>
                        <p style={{fontWeight: "bold"}} >En caso de ser Agente</p>
                        
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={2.4} md={2.4}>
                        <CustomInput
                            id="agent_link"
                            labelText="Link de Agente"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              onChange: this.handleTextInputs_State,
                              value: this.state.body.agent_link,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div><span class="material-icons">co_present</span></div>
                                </InputAdornment>
                              ),
                            }}
                            />
                      </GridItem>
                    </GridContainer>
                    </form>
                </CardBody>
                <CardFooter>
                  <GridItem xs={9} sm={9} md={9}>
                    <Button onClick={this.handleImageUpload_submitHandler} color="success" >Crear Tarjeta</Button>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    {/* <FormControlLabel
                      control={<Checkbox checked={this.state.SearchForActive} onChange={this.handleCheckboxChange} name="checkedA" />}
                      label="Pagina de servicio"
                    /> */}
                  </GridItem>
                </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={1} md={3}>
            <NewCard 
                handleCardProps={this.handleCardProps}
                company={this.state.body.company}
                title={this.state.body.agent_name}
                agent_id={this.state.body.agent_id}
                body={this.state.body.email}
                created_at={this.state.body.created_at}
                content={JSON.stringify(this.state.agent_data)}
                buttonText=""
                google_maps={this.state.body.google_maps}
                facebook={this.state.body.facebook}
                instagram={this.state.body.instagram}
                twitter={this.state.body.twitter}
                linkedin={this.state.body.linkedin}
                mercadolibre={this.state.body.mercadolibre}
                amazon={this.state.body.amazon}
                shopify={this.state.body.shopify}
                ebay={this.state.body.ebay}
                otro={this.state.body.otro}
                agent_link={this.state.body.agent_link}
              />
            </GridItem>
            </GridContainer>
            {/* Test QR code only */}
            {/* <Button onClick={this.handleQR_Create} color="success" >Ver QR</Button>
            <img src={this.state.agent_data.qrcode} style={{width: "128px", height: "128px"}}/> */}
            <GridContainer>
              <GridItem xs={12} sm={12} md={9}>
                <Card>
                  <CardHeader color="primary">
                      <h4 className={this.state.classes.cardTitleWhite}>Fondos de Pantalla Predefinidos</h4>
                      <p className={this.state.classes.cardCategoryWhite}>Elija un fondo o seleccione un archivo (1080x1920)<br></br> El fondo azul de los botones no es parte de su estilo, por lo que no se guardará.</p>
                  </CardHeader>
                  <CardBody>
                  <GridItem xs={12} sm={12} md={6}>
                    <strong>Fondo de pantalla:</strong> <input onChange={this.handleBackgroundImagePreview} type="file" accept="image/png,image/gif,image/jpeg"></input>
                    <br></br>
                    <br></br>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <center>
                    <div>
                      <img id={bg1} src={bg1} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo1" />
                      <img id={bg2} src={bg2} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo2" />
                      <img id={bg3} src={bg3} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo3" />
                      <img id={bg4} src={bg4} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo4" />
                      <img id={bg5} src={bg5} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo5" />
                      <img id={bg6} src={bg6} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo6" />
                      <img id={bg7} src={bg7} width="98px" height="192px" style={{marginRight: "5px"}} class="thumbnail" onClick={this.handleBgImageClick} alt="Fondo7" />
                      
                    </div>
                    </center>
                  </GridItem>
                  <DropdownMenu
                  retButtonStyle={this.retButtonStyle}
                  />
                  </CardBody>
                </Card>
              </GridItem>
              <Hidden>
                <GridItem xs={12} sm={12} md={3}>
                  <Card>
                    <CardBody>
                      <center>
                        <p>Seleccionado:</p>
                        <img src={this.state.PreviewBgImage} width="128px" height="264px" style={{marginRight: "5px"}} alt="Previsualizacion" />
                        <div>
                          <Button id="DisplayButtons1_1" style={{backgroundImage: "url(" + this.state.styleb5 + ")"}}></Button>
                          <Button id="DisplayButtons1_1" style={{backgroundImage: "url(" + this.state.styleb1 + ")"}}></Button>
                          <Button id="DisplayButtons1_2" style={{backgroundImage: "url(" + this.state.styleb2 + ")"}}></Button>
                        </div>
                        <div>
                          <Button id="DisplayButtons1_3" style={{backgroundImage: "url(" + this.state.styleb3 + ")"}}></Button>
                          <Button id="DisplayButtons1_4" style={{backgroundImage: "url(" + this.state.styleb4 + ")"}}></Button>
                        </div>
                      </center>
                    </CardBody>
                  </Card>
                </GridItem>
              </Hidden>
            </GridContainer>
        </div>
        );
    }
}

export default Create;