import React from "react";
import "./ImageDisplay.css"
import OutsideAlerter from "./OutsideAlerter"
// import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import closebtn from "../../assets/img/closebtn.png"

export default function ImageDisplay(props){
    const image = props.image;
    return (
        <div className="blackBackground">
            <img alt="cerrar" src={closebtn} className="quitButton"></img>
            <div className="quitButton"></div>
            <OutsideAlerter handleDisplayImage={props.handleDisplayImage}>
                <img alt="Imagen" className="displayImage" src={image}></img>
            </OutsideAlerter>

            {/* <div className="blackBackground">
            <img alt="cerrar" src={closebtn} className="quitButton"></img>
            <div className="quitButton"></div>
            <OutsideAlerter handleDisplayImage={props.handleDisplayImage}>
                <img alt="Imagen" className="displayImage" src={image} style={{width: "100vw", height: "auto"}}></img>
            </OutsideAlerter>
            </div> */}
        </div>


    );
}