import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

//Social media icons
import './Card.css'

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import TextField from '@material-ui/core/TextField';

import styles from "assets/jss/material-dashboard-react/cardImagesStyles.js";
import SelectImage from 'assets/img/UploadImage.png'

const useStyles = makeStyles(styles);

export default function CardProduct(props) {
  const classes = useStyles();
  const [image, setImage] = React.useState(() => {
    if(props.cardImage !== ""){
      return props.cardImage
    } else{
      return SelectImage
    }})
  const cardTitle = props.cardTitle;
  const cardName = props.cardName;
  const cardDescription = props.cardDescription;
  const inputFile = React.useRef(null);

  const state = {};

  //Initialize setup for dynamic state change
  state.cardTitle = props.setCardTitle;
  state.cardName = props.setCardName;
  state.cardDescription = props.setCardDescription;
  state.cardImage = props.setCardImage;

  const handleTextChange= (e) =>{
    let name = e.target.id;

    //Length validation
    if(!(e.target.value.length <=280)) return 0;

    state[name](e.target.value);
  }

  // function handleCardProps(){
  //   props.handleCardProps(props.company, props.title, props.agent_id, props.body, props.created_at, props.content, props.active);
  // }
  function handleEditCardClick(){
    props.handleEditCardClick(props.company, props.title, props.agent_id, props.body, props.created_at, props.content, props.active, props.backgroundImage, props.social_links);
  }
  function handleDeleteCardClick(){
    props.handleDeleteCardClick(props.company, props.agent_id, props.content);
  }
  // function handleSocialMediaCardClick(event){
  //   console.log(event)
  // }


  var CardValues = {
    company: props.company,
    title: props.title,
    agent_id: props.agent_id,
    body: props.body,
    created_at: props.created_at,
    buttonText: props.buttonText,
    buttonTrue: true
  }

  const imageClick_handleFileUpload = () => {
    inputFile.current.click();
  }
  const updateSelectedImage = (e) => {
    setImage(URL.createObjectURL(inputFile.current.files[0]));
    state.cardImage(inputFile.current.files[0]);

  }

  if(props){
    if(props.company === '') CardValues.company = "Compañia"
    if(props.title === '') CardValues.title = "Nombre"
    if(props.agent_id === '') CardValues.agent_id = "0"
    if(props.body === '') CardValues.body = "ejemplo@ejemplo.com"
    if(props.created_at === '') CardValues.created_at = "0"
    if(props.buttonText === '') CardValues.buttonTrue = false
  }

  return (
    <div >
      <Card style={{ width: "20rem" }}>
          <div>
            {props.handleEditCardClick &&
              <IconButton onClick={handleEditCardClick} style={{position: "absolute", borderRadius: "100%", backgroundColor: "green", right: "11%", top: "1%"}} size="small" aria-label="edit" className={classes.margin}>
                <EditIcon style={{ color: "white" }} fontSize="small"/>
              </IconButton>}
          </div>
          <div>
            {props.handleDeleteCardClick &&
              <IconButton onClick={handleDeleteCardClick} style={{position: "absolute", borderRadius: "100%", backgroundColor: "red", right: "1%", top: "1%"}} size="small" aria-label="delete" className={classes.margin}>
                <DeleteIcon style={{ color: "white" }} fontSize="small"/>
              </IconButton>}
          </div>
          <img
          className={classes.cardImgTop}
          data-src="holder.js/100px180/"
          alt="100%x180"
          style={{ height: "180px", width: "100%", display: "block" }}
          src={image}
          data-holder-rendered="true"
          onClick={imageClick_handleFileUpload}
          />
        <CardBody>
        <GridContainer xs={12} sm={12} md={12}> 
                    <GridItem xs={12} sm={12} md={4}>
                    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop: "-25px"}}>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                id="cardTitle"
                                labelText="Titulo"
                                error={false}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: handleTextChange,
                                    value: cardTitle,
                                }}
                            />
                        </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop: "-30px"}}>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                id="cardName"
                                labelText="Nombre"
                                error={false}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: handleTextChange,
                                    value: cardName,
                                }}
                            />
                        </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth={true}
                                id="cardDescription"
                                multiline
                                label="Descripcion"
                                rowsMax={4}
                                value={cardDescription}
                                onChange={handleTextChange}
                            />
                        </GridItem>
                    </GridItem>
                </GridContainer>
        </CardBody>
        <input id="fileInp" type="file" ref={inputFile} onChange={updateSelectedImage} accept="image/png,image/gif,image/jpeg" 
        style={{visibility: "hidden", margin: "0px", position: "absolute"}}></input>
      </Card>
    </div>
  );
}