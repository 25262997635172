import React, { Component} from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import NewCard from "views/Card/Card"
import AddProducts from "views/Card/AddProducts.js"
import Update from "../Card/Update";
import SnackbarContent from "components/Snackbar/SnackbarContent2.js";
import { Hidden} from "@material-ui/core";

import { Auth } from "aws-amplify";
import TableList from "views/TableList/TableList";
import loading from 'assets/img/loading.gif'
import config from 'config'


//Toast notifications
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Axios HTTP Methods
import axios from 'axios';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

var gridItemOptions = {
  agent_id: true,
  agent_name: false,
  email: false,
  listview: false
}


export default class Read extends Component {
  
  
    constructor(props){
        super(props)
  
        this.state = {
          IDToken: '',
          body: {
            company: "",
            created_at: "",
            agent_id: "",
            agent_name: "",
            agent_link: "",
            email: "",
            content: "",
            query_type: "equals",
            active: true,
            backgroundImage: "",
            products: ""
          },
          wantToDelete: false,
          wantToEdit: false,
          wantToAddProducts: false,
          classes: useStyles,
          isReading: false,
          Readed: false,
          DisplayAll: false,
          currentCards: [],
          CardProps: {},
          status: ""
          
        }
    }

    // Sets JWT Token in React's State var "IDToken"
    async componentDidMount() {
      if(this.props.rest.validationProps.isViewingUser){
        this.props.rest.validationProps.setIsViewingUser(false);
      }
        try{
          let session = await Auth.currentSession();
          let jwt = session.getIdToken();
          this.setState({IDToken: jwt.getJwtToken()});
          let currentCards = JSON.parse(localStorage.getItem('currentCards'));
          if(currentCards === null) currentCards = 0;
          if((!(currentCards === null) && !(currentCards.length > 0)) || this.props.rest.auth.reloadPending){
            this.props.rest.auth.setReloadPending(false);
            this.submitHandlerScan()
          } else{
            this.setState({currentCards: JSON.parse(localStorage.getItem('currentCards'))})
          }
        }catch(error){
          console.log(error)
        }
      }

    // Simple Method to display Success notifications 
    notifyGood = () =>{
        toast.success('Tarjeta cargada.', {position: toast.POSITION.TOP_CENTER});
      }
    notifyMessage = (message) =>{
      toast.success(message, {position: toast.POSITION.TOP_CENTER});
    }
  
    // Simple Method to display Error notifications 
    notifyBad = (message) =>{
    toast.error(message, {position: toast.POSITION.TOP_CENTER});
    }

    refreshTable = () =>{
        this.submitHandlerScan(); 
    }

    handleLoadingGif = (isReading, status) => {
      this.setState({isReading: isReading});
      this.setState({status: status});
    }

    // Method to obtain jwt Token and apply string token to React's state
    getTokens = () => {
      try{
        Auth.currentSession().then(res=>{
          let accessToken = res.getAccessToken();
          let jwt = accessToken.getJwtToken();
          this.setState({IDToken: jwt});
        })
      } catch(error){
        console.log(error)
      }
    };


    submitHandlerScan = () =>{
      this.setState({status: "Obteniendo Tarjetas"});
      if(this.props.rest.auth.isAuthenticated === false){
        this.notifyBad('Necesita iniciar sesion para ver una tarjeta.');
        return 0;
      }
      
      let parametros = {

          company: "Qualitas",
          query_type: "all"
      }
      
      this.setState({isReading: true});
      axios.defaults.headers.common['IDToken'] = this.state.IDToken;
      axios.get(config.api.invokeUrl, { params: parametros })
      .then(response => {
        let statusCode = response.data.statusCode;
        this.setState({currentCards: response.data.notes});
        localStorage.removeItem('currentCards')
        localStorage.setItem('currentCards', JSON.stringify(response.data.notes));
        this.props.rest.auth.setReloadPending(false);

        if (statusCode !== 400){
          this.setState({Readed: true});
        }else{
          if(response.data.message === 'The ID Token does not containt user_id'){
            this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
          }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){
            this.notifyBad('Favor de llenar todos los campos.');
          }
          else{
            this.notifyBad('Una tarjeta con ese correo ya existe.');
          }
          
        }
        this.setState({isReading: false});
      })
      .catch(error =>{
        console.log(error);
        this.notifyBad(error);
        this.setState({isReading: false});
      })
      
  };

  submitHandlerDelete = () =>{
    this.setState({status: "Borrando Tarjeta"});
    // Sets loading gif while Reading cards
    this.setState({isReading: true});
    //Checks if the user is authenticated
    if(this.props.rest.auth.isAuthenticated === false){
      this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
      return 0;
    }

    //Sets object with User token and also sets params
    let object = {
        company: this.state.body.company,
        agent_id: this.state.body.agent_id
    }

    axios.defaults.headers.common['IDToken'] = this.state.IDToken;
    axios.delete(config.api.invokeUrl, { params: object })
    .then(response => {
      let statusCode = response.data.statusCode;

      if (statusCode !== 400){
          localStorage.removeItem('currentCards')
          this.props.rest.auth.setReloadPending(true);
          this.notifyMessage("Tarjeta Eliminada");
          this.submitHandlerScan();
      }else{
        if(response.data.message === 'The ID Token does not containt user_id'){
          this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
        }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){
          this.notifyBad('Favor de llenar todos los campos.');
        }
        else{
          this.notifyBad('Una tarjeta con ese correo ya existe.');
        }
        
      }
      this.setState({isCreating: false});
    })
    .catch(error =>{
      console.log(error);
      this.notifyBad(error);
      this.setState({isCreating: false});
    })
    
};

    gridItemHandler = (e) =>{
      let name = e.target.id

      gridItemOptions.agent_id = false
      gridItemOptions.agent_name = false
      gridItemOptions.email = false

      gridItemOptions[name] = true
      this.setState({}) //Re renders components
    };

    handleListView = (e) =>{
      gridItemOptions.listview = true;
      this.setState({}) //Re renders components
    };

    handleEditCardClick = (company, agent_name, agent_link, agent_position, agent_id, email, created_at, content, active, backgroundImage, social_links) =>{
      let body = this.state.body
      let content_data = JSON.parse(content);
      let social_links_data = JSON.parse(social_links);

      body.company = company
      body.agent_name = agent_name 
      body.agent_link = agent_link 
      body.agent_id = agent_id
      body.agent_position = agent_position
      body.email = email
      body.created_at = created_at 
      body.content = content
      body.qrcode = content_data.qrcode
      body.image = content_data.image
      body.number = content_data.number
      body.address = content_data.address
      body.active = active
      body.backgroundImage = backgroundImage
      body.google_maps = social_links_data.google_maps
      body.facebook = social_links_data.facebook
      body.instagram = social_links_data.instagram
      body.twitter = social_links_data.twitter
      body.linkedin = social_links_data.linkedin
      body.mercadolibre = social_links_data.mercadolibre
      body.amazon = social_links_data.amazon
      body.shopify = social_links_data.shopify
      body.ebay = social_links_data.ebay
      body.otro = social_links_data.otro
      this.setState({body: body, wantToEdit: true});
    };
    handleCancelEdit = () =>{
      this.setState({wantToEdit: false})
    }

    handleSuccessEdit = () =>{
      this.setState({wantToEdit: false})
      this.submitHandlerScan();
    }

    handleDeleteCardClick = (company, agent_id, content) =>{
      let body = this.state.body
      
      body.company = company
      body.agent_name = "" 
      body.agent_id = agent_id 
      body.email = "" 
      body.created_at = "" 
      body.content = content
      body.qrcode = content.qrcode

      this.setState({body: body, wantToDelete: true});
      
    };

    handleSuccessDelete = () =>{
      this.setState({wantToDelete: false})
      this.submitHandlerDelete();
    }
    handleCancelDelete = () =>{
      this.setState({wantToDelete: false})
    }

    handleAddProductsCardClick = (company, agent_id, agent_name, content, products) => {
      let body = this.state.body
      body.company = company
      body.agent_name = agent_name
      body.agent_id = agent_id
      body.email = "" 
      body.created_at = "" 
      body.content = content
      body.qrcode = ""
      body.products = products

      this.setState({body: body, wantToAddProducts: true});
    }

    handleSuccessAddProducts = () =>{
      this.setState({wantToAddProducts: false})
      this.submitHandlerScan();
    }
    handleCancelAddProducts = () =>{
      this.setState({wantToAddProducts: false})
    }

    handleCardProps = (company, agent_name, agent_id, email, created_at, content, active) =>{
      let CardProps = {
        company: company,
        agent_name: agent_name,
        agent_id: agent_id,
        email: email,
        created_at: created_at,
        content: content,
        active: active
      }
      this.setState({CardProps: CardProps});
      this.props.rest.history.push(`/admin/tarjeta/?c=${encodeURIComponent(company)}&id=${agent_id}`);
    };

    render() {
        const elements = this.state.currentCards;

        // eslint-disable-next-line
        const listItems = elements.map((element) =>
        <div className='row' key={element.agent_id}>
          <NewCard 
          handleCardProps={this.handleCardProps}
          company={element.company}
          title={element.agent_name} 
          agent_id={element.agent_id} 
          body={element.email} 
          created_at={element.created_at} 
          content={element.content}
          active={element.active} 
          buttonText="Visualizar"/>
        </div> );

        const listTable = <TableList 
        currentCards={this.state.currentCards}
        handleEditCardClick={this.handleEditCardClick}
        handleDeleteCardClick={this.handleDeleteCardClick}
        handleCardProps={this.handleCardProps}
        handleAddProductsCardClick={this.handleAddProductsCardClick}
        refreshTable={this.refreshTable}
        />
        
        //Renders Loading gif while making requests
        if(this.state.isReading){
          return (
            <center>
            <Hidden mdUp>
              <img src={loading} alt="loading..." width="80%"  height="80%"/>
              <h2>{this.state.status}</h2>
            </Hidden>
            <Hidden mdDown>
              <img src={loading} alt="loading..."/>
              <h1>{this.state.status}</h1>
            </Hidden>
          </center>
          )
        }

        //Renders Edit Card Panel
        if(this.state.wantToAddProducts){
          return <AddProducts
          company={this.state.body.company}
          agent_id={this.state.body.agent_id}
          agent_name={this.state.body.agent_name}
          content={this.state.body.content}
          products={this.state.body.products}
          handleSuccessAddProducts={this.handleSuccessAddProducts}
          handleCancelAddProducts={this.handleCancelAddProducts}
          handleLoadingGif={this.handleLoadingGif}
          IDToken={this.state.IDToken}
          auth={this.props.rest.auth}
          />
        }

        //Renders Edit Card Panel
        if(this.state.wantToEdit){
          return <Update
          company={this.state.body.company}
          created_at={this.state.body.created_at}
          agent_id={this.state.body.agent_id}
          agent_name={this.state.body.agent_name}
          agent_link={this.state.body.agent_link}
          agent_position={this.state.body.agent_position}
          email={this.state.body.email}
          content={this.state.body.content}
          number={this.state.body.number}
          qrcode={this.state.body.qrcode}
          image={this.state.body.image}
          address={this.state.body.address}
          active={this.state.body.active}
          handleCancelEdit={this.handleCancelEdit}
          handleSuccessEdit={this.handleSuccessEdit}
          backgroundImage={JSON.parse(this.state.body.backgroundImage)}
          props={this.props}
          google_maps={this.state.body.google_maps}
          facebook={this.state.body.facebook}
          instagram={this.state.body.instagram}
          twitter={this.state.body.twitter}
          amazon={this.state.body.amazon}
          linkedin={this.state.body.linkedin}
          mercadolibre={this.state.body.mercadolibre}
          ebay={this.state.body.ebay}
          shopify={this.state.body.shopify}
          otro={this.state.body.otro}
          handleBadgeClick={this.handleBadgeClick}
          />
        }

        if (this.props.rest.auth.isAuthenticated === false) {
            return <Redirect to='/admin/login' />
          }
        return (
          <div>
            <SnackbarContent handleCancelDelete={this.handleCancelDelete} handleSuccessDelete={this.handleSuccessDelete} wantToDelete={this.state.wantToDelete} company={this.state.body.creat} created_at={this.state.body.created_at}></SnackbarContent>
            
              {listTable}
          </div>  
        );
    }
}
