import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

//Social media icons
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './Card.css'

import Amazon from '../../assets/img/amazonicon.png'
import EbayIcon from '../../assets/img/ebayicon.png'
import ShopifyIcon from '../../assets/img/shopifyicon.png'
import MercadolibreIcon from '../../assets/img/mercadolibreicon.png'
import Otro from '@material-ui/icons/Add';

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import ImageDisplay from "components/ImageDisplay/ImageDisplay";

import styles from "assets/jss/material-dashboard-react/cardImagesStyles.js";
import config from 'config'

const useStyles = makeStyles(styles);

export default function CardBasic(props) {
  // const [displayImage, setDisplayImage] = React.useState();
  const classes = useStyles();
  function handleCardProps(){
    props.handleCardProps(props.company, props.title, props.agent_id, props.body, props.created_at, props.content, props.active);
  }
  function handleEditCardClick(){
    props.handleEditCardClick(props.company, props.title, props.agent_position, props.agent_id, props.body, props.created_at, props.content, props.active, props.backgroundImage, props.social_links);
  }
  function handleDeleteCardClick(){
    props.handleDeleteCardClick(props.company, props.agent_id, props.content);
  }
  function handleAddProductsCardClick(){
    props.handleAddProductsCardClick(props.company, props.agent_id, props.title, props.content, props.products);
  }
  function handleSocialMediaCardClick(link){
    let sublink = link.substring(0,4)
    if(sublink !== "http")
        window.open(`https://${link}`, '_blank');
    else
        window.open(link, '_blank');
  }

  var content = {
    image: config.defaultImageForCards.image1
    // number: "6645555555"
  }



  var CardValues = {
    company: props.company,
    title: props.title,
    agent_id: props.agent_id,
    body: props.body,
    created_at: props.created_at,
    buttonText: props.buttonText,
    buttonTrue: true
  }

  if(props){
    if(props.company === '') CardValues.company = "Compañia"
    if(props.title === '') CardValues.title = "Nombre"
    if(props.agent_id === '') CardValues.agent_id = "0"
    if(props.body === '') CardValues.body = "ejemplo@ejemplo.com"
    if(props.created_at === '') CardValues.created_at = "0"
    if(props.buttonText === '') CardValues.buttonTrue = false
  }

  if(props.content){
    console.log("My image: ", props.content)

    content = JSON.parse(props.content)
    if(content.image === ""){
      content.image = config.defaultImageForCards.image1
    } else if (content.qrcode !== undefined && content.image === ""){
      console.log("something happening")
      let full_url = content.qrcode.split('users/')[0]
      let image = content.image.split('users/')[1]
      content.image = `${full_url}users/${encodeURIComponent(image)}`
      console.log(content.image)

    }
  };

  // function handleDisplayImage(){
  //   setDisplayImage(!displayImage);
  //   console.log("hello: ", displayImage)
    
  // }
  // console.log("display: ", displayImage)

  // if(displayImage){
  //   // this.props.rest.history.push(`/admin/create?p=${encodeURI(this.state.selectedImage)}`)
  //   return (
  //       <>
            
  //       </>
  //   )
  // }

  return (
    <div >
      {/* {displayImage &&
        <ImageDisplay handleDisplayImage={handleDisplayImage} image={content.image}></ImageDisplay>
        } */}
      <Card style={{ width: "20rem" }}>
          <div>
            {props.handleEditCardClick &&
              <IconButton onClick={handleEditCardClick} style={{position: "absolute", borderRadius: "100%", backgroundColor: "green", right: "11%", top: "1%", zIndex: "1"}} size="small" aria-label="edit" className={classes.margin}>
                <EditIcon style={{ color: "white" }} fontSize="small"/>
              </IconButton>}
          </div>
          <div>
            {props.handleDeleteCardClick &&
              <IconButton onClick={handleDeleteCardClick} style={{position: "absolute", borderRadius: "100%", backgroundColor: "red", right: "1%", top: "1%", zIndex: "1"}} size="small" aria-label="delete" className={classes.margin}>
                <DeleteIcon style={{ color: "white" }} fontSize="small"/>
              </IconButton>}
          </div>
          <div>
            {props.handleAddProductsCardClick &&
              <IconButton onClick={handleAddProductsCardClick} style={{position: "absolute", borderRadius: "100%", backgroundColor: "gray", right: "21%", top: "1%", zIndex: "1"}} size="small" aria-label="delete" className={classes.margin}>
                <MonetizationOnIcon style={{ color: "#FFD700" }} fontSize="small"/>
              </IconButton>}
          </div>
        <div style={{width: "100%", height: "180px"}}>
        <img
          // id="cardImage"
          className={classes.cardImgTop}
          data-src="holder.js/100px180/"
          alt="100%x180"
          // style={{ objectFit: "contain"}}
          width="100%"
          height="180px"
          src={content.image}
          data-holder-rendered="true"
          // onClick={handleDisplayImage}
        />
        </div>
        <CardBody>
          <div>

          <h5 style={{float: "left", margin: "2px"}}><strong>{CardValues.company} </strong></h5>
          {/* <h5 style={{float: "right", margin: "2px"}}><strong> ID:{CardValues.agent_id}</strong></h5> */}
          </div>
          <br></br>
          
          <h3 style={{textAlign: "left"}}> {CardValues.title}</h3> 
          
          <p style={{textAlign: "justify"}}>
            {CardValues.body}
            <br/>
            {content.number}
          </p>
          <div>
          
            {props.facebook && 
                <FacebookIcon id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.facebook)} style={{ color: "#3b5998", margin: "5px"}}/>}
            {props.instagram && 
                <InstagramIcon id="socialMediaButtons"onClick={() => handleSocialMediaCardClick(props.instagram)} style={{ color: "#bc2a8d", margin: "5px"}}/>}
            {props.twitter && 
                <TwitterIcon id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.twitter)} style={{ color: "#26a7de", margin: "5px"}}/>}
            {props.linkedin && 
                <LinkedInIcon id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.linkedin)} style={{ color: "#0072b1", margin: "5px"}}/>}
            {props.amazon && 
                <img onClick={() => handleSocialMediaCardClick(props.amazon)} src={Amazon}  width="24px" height="24px" style={{ margin: "5px", cursor: "pointer"}} alt="amazon" />}
            {props.ebay && 
                <img onClick={() => handleSocialMediaCardClick(props.ebay)} src={EbayIcon}  width="24px" height="24px" style={{ margin: "5px", cursor: "pointer"}} alt="ebay" />}
            {props.shopify && 
                <img onClick={() => handleSocialMediaCardClick(props.shopify)} src={ShopifyIcon}  width="24px" height="24px" style={{ margin: "5px", cursor: "pointer"}} alt="shopify" />}
            {props.mercadolibre && 
                <img onClick={() => handleSocialMediaCardClick(props.mercadolibre)} src={MercadolibreIcon}  width="24px" height="24px" style={{ margin: "5px", cursor: "pointer"}} alt="mercado libre" />}
            {props.otro && 
                <Otro id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.otro)} style={{ color: "green", margin: "5px"}} alt="otro" />}
          </div>
          {CardValues.buttonTrue && <Button onClick={handleCardProps} color="primary">{CardValues.buttonText}</Button>}
          
        </CardBody>
      </Card>
    </div>
  );
}