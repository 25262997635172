import React, { Component, lazy, Suspense } from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "components/Snackbar/Snackbar.js";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//import NewCard from "views/Card/Card"

import AddProducts from "views/Card/AddProducts.js"
import './Read.css'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Auth } from "aws-amplify";
import FormErrors from "views/Login/FormErrors";
import TableList from "views/TableList/TableList";
import loading from 'assets/img/loading.gif'
import config from 'config'


//Toast notifications
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Axios HTTP Methods
import axios from 'axios';
import Update from "./Update";
import SnackbarContent from "components/Snackbar/SnackbarContent2.js";
import { Hidden } from "@material-ui/core";

const NewCard = lazy(() => import("views/Card/Card"));
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

var gridItemOptions = {
  agent_id: true,
  agent_name: false,
  email: false,
  listview: false
}

export default class Read extends Component {
  
  
    constructor(props){
        super(props)
        this.handleDataValidation = this.handleDataValidation.bind(this);

        this.state = {
          IDToken: '',
          body: {
            company: "",
            created_at: "",
            agent_id: "",
            agent_name: "",
            agent_position: "",
            email: "",
            content: "",
            qrcode: "",
            address: "",
            number: "",
            image: "",
            query_type: "equals",
            username: this.props.rest.auth.user.username,
            active: true,
            backgroundImage: "",
            google_maps: "",
            facebook: "",
            instagram: "",
            twitter: "",
            linkedin: "",
            mercadolibre: "",
            amazon: "",
            shopify: "",
            ebay: "",
            otro: "",
            products: ""
          },
          delete: {
            company: "",
            created_at: "",
          },
          classes: useStyles,
          isReading: false,
          Readed: false,
          DisplayAll: false,
          wantToEdit: false,
          wantToDelete: false,
          wantTo: false,
          currentCards: [],
          inactiveCards: [],
          activeCards: [],
          CardProps: {},
          InputErrors: {
            company: false,
            email: false,
            agent_id: false,
            agent_name: false,
          },
          SnackBar: {
            message: "",
            color: "",
            close: "",
            icon: "",
            place: "",
            open: ""
          },
          IsUsingInput: {
            agent_name: false,
            email: false
          },
          status: "",
          viewType: "Vista de Lista",
          SearchForActive: true
          
        }
    }

    // Sets JWT Token in React's State var "IDToken"
    async componentDidMount() {
      if(this.props.rest.validationProps.isViewingUser){
        this.props.rest.validationProps.setIsViewingUser(false);
      }
        try{
          let session = await Auth.currentSession();
          let jwt = session.getIdToken();
          this.setState({IDToken: jwt.getJwtToken()});
        }catch(error){
          console.log(error);
        }
      }

    // Simple Method to display Success notifications 
    notifyGood = () =>{
        toast.success('Tarjeta cargada.', {position: toast.POSITION.TOP_CENTER});
      }
  
    // Simple Method to display Error notifications 
    notifyBad = (message) =>{
    toast.error(message, {position: toast.POSITION.TOP_CENTER});
    }

    // Quits Display All Cards view returning to search view
    handleBack = () => {
      this.setState({DisplayAll: false});
      };
    
    handleLoadingGif = (isReading, status) => {
      this.setState({isReading: isReading});
      this.setState({status: status});
    }

    handleCardStatusCollection = (CardsArray) => {
      let inactiveCards = [];
      let activeCards = [];
      let CardCollection = [];
      
      // eslint-disable-next-line
      CardsArray.map((element) => {
          if(element.active === false){
            inactiveCards.push(element);
          } else {
            activeCards.push(element);
          }
      });
      if(this.state.SearchForActive === true) CardCollection = activeCards;
      else CardCollection = inactiveCards;

      this.setState({currentCards: CardCollection, activeCards: activeCards, inactiveCards: inactiveCards});
    }

    submitHandlerRead = () =>{
        this.setState({status: "Leyendo Tarjetas"});
        // Data Validation 'used inputs' and 'input errors'
        if(!(this.handleDataValidation())) return 0;

        //Checks if the user is authenticated
        if(this.props.rest.auth.isAuthenticated === false){
          this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
          return 0;
        }
        // Sets loading gif while Reading cards
        this.setState({isReading: true});

        // Creates object parametros with the highest priority parameter used and always the company
        let parametros = this.handleParametersSearchPriority();
        parametros.company = this.state.body.company;

        axios.defaults.headers.common['IDToken'] = this.state.IDToken;
        axios.get(config.api.invokeUrl, { params: parametros })
        .then(response => {

          this.handleCardStatusCollection(response.data.notes)
          let statusCode = response.data.statusCode;

          if (statusCode !== 400){
            if(response.data.notes.length === 0){
              let message = ""
              if(gridItemOptions.agent_id) {message = "con ese numero de agente"}
              else if (gridItemOptions.agent_name) {message = "con ese nombre"}
              else if (gridItemOptions.email) {message = "con ese email"}
              message = `No existe ninguna tarjeta ${message}.`;

              this.notifyBad(message);
            }else{
              this.setState({DisplayAll: true});
            }
          }else{
            if(response.data.message === 'The ID Token does not containt user_id'){
              this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
            }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){
              this.notifyBad('Favor de llenar todos los campos.');
            }
            else{
              this.notifyBad('Una tarjeta con ese correo ya existe.');
            }
            
          }
          this.setState({isReading: false});
        })
        .catch(error =>{
          console.log(error);
          this.notifyBad(error);
          this.setState({isReading: false});
        })
        
    };

    submitHandlerDelete = () =>{
      this.setState({status: "Borrando Tarjeta"});
      //Checks if the user is authenticated
      if(this.props.rest.auth.isAuthenticated === false){
        this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
        return 0;
      }
      // Sets loading gif while Reading cards
      this.setState({isReading: true});

      //Sets object with User token and also sets params
      let object = {
          company: this.state.body.company,
          agent_id: this.state.body.agent_id
      }

      axios.defaults.headers.common['IDToken'] = this.state.IDToken;
      axios.delete(config.api.invokeUrl, { params: object })
      .then(response => {
        let statusCode = response.data.statusCode;

        if (statusCode !== 400){
            this.props.rest.auth.setReloadPending(true);
            this.submitHandlerScan();
        }else{
          if(response.data.message === 'The ID Token does not containt user_id'){
            this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
          }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){
            this.notifyBad('Favor de llenar todos los campos.');
          }
          else{
            this.notifyBad('Una tarjeta con ese correo ya existe.');
          }
          
        }
        this.setState({isReading: false});
      })
      .catch(error =>{
        console.log(error);
        this.notifyBad(error);
        this.setState({isReading: false});
      })
      
  };

    submitHandlerScan = () =>{
      this.setState({status: "Obteniendo Tarjetas"});

      if(this.props.rest.auth.isAuthenticated === false){
        this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
        return 0;
      }

      let parametros = {
          company: "Qualitas",
          query_type: "all"
      }


      this.setState({isReading: true});
      axios.defaults.headers.common['IDToken'] = this.state.IDToken;
      axios.get(config.api.invokeUrl, { params: parametros } )
      .then(response => {
        this.setState({DisplayAll: true})
        
        let statusCode = response.data.statusCode;
        this.handleCardStatusCollection(response.data.notes)
        if (statusCode !== 400){
          this.setState({Readed: true});
        }else{
          if(response.data.message === 'The ID Token does not containt user_id'){
            this.notifyBad('Necesita iniciar sesion para publicar una tarjeta.');
          }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){
            this.notifyBad('Favor de llenar todos los campos.');
          }
          else{
            this.notifyBad('Una tarjeta con ese correo ya existe.');
          }
          
        }
        this.setState({isReading: false});
      })
      .catch(error =>{
        console.log(error);
        this.notifyBad(error);
        this.setState({isReading: false});
      })
    };

    handleParametersSearchPriority() {
      let IsUsingInput = this.state.IsUsingInput;
      let parametros = {}
      if(IsUsingInput.agent_name){
        parametros.agent_name = this.state.body.agent_name
        return parametros;
      } else if(IsUsingInput.email){
        parametros.email = this.state.body.email
        return parametros;
      } else return parametros;
    }

    handleDataValidation() {
      let error = false;
      let body = this.state.body;
      let InputErrors = this.state.InputErrors;
      let IsUsingInput = this.state.IsUsingInput;

      if(body.company === ''){
        error = true;
        InputErrors.company = true
      }
      if(body.agent_name === ''){
        IsUsingInput.agent_name = false
      }
      if(body.email === ''){
        IsUsingInput.email = false
      }
      this.setState({IsUsingInput: IsUsingInput});
      if(error){
        this.notifyBad('Favor de llenar los campos correspondientes.');
        this.setState({InputErrors: InputErrors});
        return false;
      }else{
        InputErrors.company = false
        InputErrors.agent_name = false
        InputErrors.email = false
      }
      return true;
    }

    handleNumberInputs_StateAndErrors = (e) => {
      const onlyNumbersPattern = /^\d*(?:[0-9]\d*)?$/;
      // Gets the state attribute values
      var InputErrors = this.state.InputErrors;
      var body = this.state.body;
      var agent_data = this.state.agent_data;
      var SnackBar = this.state.SnackBar
      let IsUsingInput = this.state.IsUsingInput

      // Gets the event id name
      const name = e.target.id;

      if(!(e.target.value.length <=15)) return 0;
      // If the current value passes the validity pattern test then apply that to state
      if (onlyNumbersPattern.test(e.target.value)) {
        InputErrors[name] = false;
        body[name] = e.target.value;
        SnackBar.open = false;
        IsUsingInput[name] = true
        // Checks number for preview purposes
        if(name === 'number'){
          agent_data.number = e.target.value
          this.setState({agent_data: agent_data});
        }
        this.setState({InputErrors: InputErrors, body: body, IsUsingInput: IsUsingInput});
      }
      // Else apply errors
      else{
        SnackBar.message = "Porfavor ingrese un numero."
        SnackBar.open = true;
        SnackBar.color = "warning"
        InputErrors[name] = true;
        this.setState({InputErrors});
      }
    };

    handleTextInputs_State = (e) => {
      // Gets event's name, state attribute body and state's errors
      let name = e.target.id;
      let body = this.state.body;
      let InputErrors = this.state.InputErrors
      let IsUsingInput = this.state.IsUsingInput

      //Length validation
      if(!(e.target.value.length <=114)) return 0;
      // Updates state attribute where events name with events value and clears error
      InputErrors[name] = false;
      body[name] = e.target.value;
      IsUsingInput[name] = true
      this.setState({body: body, InputErrors: InputErrors, IsUsingInput: IsUsingInput});
    };
    

    gridItemHandler = (e) =>{
      let name = e.target.id

      gridItemOptions.agent_name = false
      gridItemOptions.email = false

      gridItemOptions[name] = true
      this.setState({}) //Re renders components
    };

    handleListView = (e) =>{
      let viewType = "";
      if(gridItemOptions.listview){
        gridItemOptions.listview = false;
        viewType = "Vista de Lista"
      } else{
        gridItemOptions.listview = true;
        viewType = "Vista de Tarjetas"
      }
      this.setState({viewType: viewType}) //Re renders components
    };

    handleEditCardClick = (company, agent_name, agent_position, agent_id, email, created_at, content, active, backgroundImage, social_links) =>{
      let body = this.state.body
      let content_data = JSON.parse(content);
      let social_links_data = JSON.parse(social_links);
      body.company = company
      body.agent_name = agent_name 
      body.agent_position = agent_position
      body.agent_id = agent_id  
      body.email = email 
      body.created_at = created_at 
      body.content = content
      body.qrcode = content_data.qrcode
      body.image = content_data.image
      body.number = content_data.number
      body.address = content_data.address
      body.active = active
      body.backgroundImage = backgroundImage
      body.google_maps = social_links_data.google_maps
      body.facebook = social_links_data.facebook
      body.instagram = social_links_data.instagram
      body.twitter = social_links_data.twitter
      body.linkedin = social_links_data.linkedin
      body.mercadolibre = social_links_data.mercadolibre
      body.amazon = social_links_data.amazon
      body.shopify = social_links_data.shopify
      body.ebay = social_links_data.ebay
      body.otro = social_links_data.otro
      this.setState({body: body, wantToEdit: true});
    };
    handleCancelEdit = () =>{
      this.setState({wantToEdit: false})
    }

    handleSuccessEdit = () =>{
      this.setState({wantToEdit: false})
      this.submitHandlerRead();
    }

    handleDeleteCardClick = (company, agent_id, content) =>{
      let body = this.state.body
      
      body.company = company
      body.agent_name = "" 
      body.agent_id = agent_id
      body.email = "" 
      body.created_at = "" 
      body.content = content
      body.qrcode = content.qrcode

      this.setState({body: body, wantToDelete: true});
      
    };

    handleSuccessDelete = () =>{
      this.setState({wantToDelete: false})
      this.submitHandlerDelete();
    }
    handleCancelDelete = () =>{
      this.setState({wantToDelete: false})
    }

    handleAddProductsCardClick = (company, agent_id, agent_name, content, products) => {
      let body = this.state.body
      body.company = company
      body.agent_name = agent_name
      body.agent_id = agent_id
      body.email = "" 
      body.created_at = "" 
      body.content = content
      body.qrcode = ""
      body.products = products

      this.setState({body: body, wantToAddProducts: true});
    }

    handleSuccessAddProducts = () =>{
      this.setState({wantToAddProducts: false})
      this.submitHandlerRead();
    }
    handleCancelAddProducts = () =>{
      this.setState({wantToAddProducts: false})
    }

    handleCardProps = (company, agent_name, agent_id, email, created_at, content, active) =>{
      let CardProps = {
        company: company,
        agent_name: agent_name,
        agent_id: agent_id,
        email: email,
        created_at: created_at,
        content: content,
        active: active
      }
      this.setState({CardProps: CardProps});
      this.props.rest.history.push(`/admin/tarjeta/?c=${encodeURIComponent(company)}&id=${agent_id}`);
    };

    handleCheckboxChange = () => {
      let boolean = this.state.SearchForActive
      boolean = !boolean

      this.setState({SearchForActive: boolean})
    }

    render() {
        const elements = this.state.currentCards;
        
        const listItems = elements.map((element) =>
        <Suspense fallback={<div>Cargando...</div>}>
        <div className='row' key={element.agent_id}>
          
            <NewCard 
            handleCardProps={this.handleCardProps}
            company={element.company}
            title={element.agent_name} 
            agent_position={element.agent_position}
            agent_id={element.agent_id} 
            body={element.email} 
            created_at={element.created_at} 
            content={element.content} 
            active={element.active}
            backgroundImage={element.backgroundImage}
            buttonText="Visualizar"
            handleEditCardClick={this.handleEditCardClick}
            handleDeleteCardClick={this.handleDeleteCardClick}
            handleAddProductsCardClick={this.handleAddProductsCardClick}
            google_maps={JSON.parse(element.social_links).google_maps}
            facebook={JSON.parse(element.social_links).facebook}
            instagram={JSON.parse(element.social_links).instagram}
            twitter={JSON.parse(element.social_links).twitter}
            amazon={JSON.parse(element.social_links).amazon}
            linkedin={JSON.parse(element.social_links).linkedin}
            mercadolibre={JSON.parse(element.social_links).mercadolibre}
            ebay={JSON.parse(element.social_links).ebay}
            shopify={JSON.parse(element.social_links).shopify}
            otro={JSON.parse(element.social_links).otro}
            social_links={element.social_links}
            handleBadgeClick={this.handleBadgeClick}
            products={JSON.parse(element.products)}
            />
          
          
        </div> </Suspense>);
        

        const listTable = <TableList 
          currentCards={this.state.currentCards}
          handleEditCardClick={this.handleEditCardClick}
          handleDeleteCardClick={this.handleDeleteCardClick}
          handleCardProps={this.handleCardProps}
          handleAddProductsCardClick={this.handleAddProductsCardClick}
          props={this.props}/>
        //Renders Loading gif while making requests
        if(this.state.isReading){
        return (
          <center>
            <Hidden mdUp>
              <img src={loading} alt="loading..." width="80%"  height="80%"/>
              <h2>{this.state.status}</h2>
            </Hidden>
            <Hidden mdDown>
              <img src={loading} alt="loading..."/>
              <h1>{this.state.status}</h1>
            </Hidden>
          </center>
        )
        }

        //Renders Edit Card Panel
        if(this.state.wantToEdit){
            return <Update
            company={this.state.body.company}
            created_at={this.state.body.created_at}
            agent_id={this.state.body.agent_id}
            agent_name={this.state.body.agent_name}
            agent_position={this.state.body.agent_position}
            email={this.state.body.email}
            content={this.state.body.content}
            number={this.state.body.number}
            qrcode={this.state.body.qrcode}
            image={this.state.body.image}
            address={this.state.body.address}
            active={this.state.body.active}
            handleCancelEdit={this.handleCancelEdit}
            handleSuccessEdit={this.handleSuccessEdit}
            backgroundImage={JSON.parse(this.state.body.backgroundImage)}
            props={this.props}
            google_maps={this.state.body.google_maps}
            facebook={this.state.body.facebook}
            instagram={this.state.body.instagram}
            twitter={this.state.body.twitter}
            amazon={this.state.body.amazon}
            linkedin={this.state.body.linkedin}
            mercadolibre={this.state.body.mercadolibre}
            ebay={this.state.body.ebay}
            shopify={this.state.body.shopify}
            otro={this.state.body.otro}
            handleBadgeClick={this.handleBadgeClick}
            />
          }
        
        //Renders Edit Card Panel
        if(this.state.wantToAddProducts){
          return <AddProducts
          company={this.state.body.company}
          agent_id={this.state.body.agent_id}
          agent_name={this.state.body.agent_name}
          content={this.state.body.content}
          products={this.state.body.products}
          handleSuccessAddProducts={this.handleSuccessAddProducts}
          handleCancelAddProducts={this.handleCancelAddProducts}
          handleLoadingGif={this.handleLoadingGif}
          IDToken={this.state.IDToken}
          auth={this.props.rest.auth}
          />
        }

        //Renders all the cards
        if(this.state.DisplayAll){
        return(
          <div>
            <SnackbarContent handleCancelDelete={this.handleCancelDelete} handleSuccessDelete={this.handleSuccessDelete} wantToDelete={this.state.wantToDelete} company={this.state.body.creat} created_at={this.state.body.created_at}></SnackbarContent>
            <div>
              <Button  style={{position: 'relative', right: 0}} onClick={this.handleBack} color="info" >Atras</Button>
              <Button onClick={this.handleListView} style={{position: 'absolute', right: "0%"}} color="transparent"><FormatListBulletedIcon/>{this.state.viewType}</Button> 
            </div>
            <div>
              {this.state.SearchForActive &&
                  <p style={{color: "green", fontWeight: "bold"}}>Tarjetas Activas</p>}
              {!this.state.SearchForActive &&
                  <p style={{color: "red", fontWeight: "bold"}}>Tarjetas Inactivas</p>}
            </div>
            {gridItemOptions.listview === false &&
              <div className='rows'>
              {listItems}
            </div>}
            
            {gridItemOptions.listview &&
              <div>
              {listTable}
            </div>}  
            
          </div>
        );}
        if (this.props.rest.auth.isAuthenticated === false) {
            return <Redirect to='/' />
          }
        return (
        /*<div>
        <div>*/
        <div>
            
            <Snackbar place="tc" open={this.state.SnackBar.open} color={this.state.SnackBar.color} message={this.state.SnackBar.message}/>
            <GridContainer > 
            <GridItem xs={12} sm={12} md={9}>
                <Card>
                <CardHeader color="primary">
                    <h4 className={this.state.classes.cardTitleWhite}>Buscar Tarjeta</h4>
                    <p className={this.state.classes.cardCategoryWhite}>Buscar por compañia y cualquier dato adicional</p>
                </CardHeader>
                <CardBody>
                <FormErrors formerrors={this.state.errors} />
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={<Checkbox checked={this.state.SearchForActive} onChange={this.handleCheckboxChange} name="checkedA" />}
                          label="Buscar por Tarjetas Activas"
                        />
                      </GridItem>
                  </GridContainer>
                    <GridContainer> {/* First Row */}
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                        id="company"
                        labelText="Compañia"
                        error={this.state.InputErrors.company}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            onChange: this.handleTextInputs_State,
                            value: this.state.body.company,
                            startAdornment: (
                              <InputAdornment position="start">
                                <BusinessIcon />
                              </InputAdornment>
                            ),
                        }}
                        />
                    </GridItem>
                     </GridContainer>
                     <GridContainer> {/* Second Row */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                        id="agent_name"
                        labelText="Nombre"
                        helperText=""
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          onChange: this.handleTextInputs_State,
                          value: this.state.body.agent_name,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                        />
                     </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                        id="email"
                        labelText="Email"
                        helperText=""
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          onChange: this.handleTextInputs_State,
                          value: this.state.body.email,
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                        />
                     </GridItem>
                    </GridContainer>
                    </form>
                </CardBody>
                <CardFooter>
                    <Button onClick={this.submitHandlerRead} color="success" >Buscar</Button>
                    <Button  style={{position: 'relative', right: 0}} onClick={this.submitHandlerScan} color="info" >Mostrar Todo</Button>
                </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
            <NewCard 
                handleCardProps={this.handleCardProps}
                company={this.state.body.company}
                title={this.state.body.agent_name}
                agent_id={this.state.body.agent_id}
                body={this.state.body.email}
                created_at={this.state.body.created_at}
                content={JSON.stringify(this.state.agent_data)}
                active={this.state.body.active}
                buttonText=""
              />
            </GridItem>
            </GridContainer>
                     
            
        
        </div>
        );
    }
}

//curl -X GET "https://fyic9zn9r5.execute-api.ap-south-1.amazonaws.com/v2/notes-api?agent_id=1&created_at=2020-06-26%2021%3A31%3A34.040553&agent_name=Julio%20Gomez&email=julio%40hotmail.com&company=Qualitas&query_type=equals" -H  "accept: application/json" -H  "Access-Token: eyJraWQiOiJpZ1k3VkI0cGtxM084WEZaemlCZG1TRUxcLzl3bTBQVVlQanlXeWx6YVUrYz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlM2JjM2MxNC01ZDAzLTRlYWMtODU2ZC02Nzg2MTNlNTEyNjAiLCJldmVudF9pZCI6IjMxMmY1OTVmLTIyZWItNGYwMS1iM2Q4LWUzNTcyZGIyMDJiMCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE1OTI4NjM2NjAsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoLTFfOFc0eVQweThpIiwiZXhwIjoxNTkzMzEyNTcyLCJpYXQiOjE1OTMzMDg5NzIsImp0aSI6IjZlYzA4MDE4LWJmM2YtNGQxOC05NmJiLWNkY2FjZjE0YjgxNCIsImNsaWVudF9pZCI6IjZzdHY4bXBwa2c4M2VlaG9jaDV0bXRlNjMzIiwidXNlcm5hbWUiOiJtYXJjLmFhIn0.J_FEe4iJZgCKKB8mu4vkpgfjAwBdAPrJkoPkz59ijAqbZ5Go3_P3MQDKfzZ4BKZEK8tbK3-BpAQj4pGFDHHWc4dNKVf-ToSXCDvhCSDvo5dIWFK4sLOi4fU11bRDwmeUzp_-6R2VwUedEPI2-6omo_2JAfekN2p8jEOrSRuwIXAHUbX4HUcnYFmmR7xQ9MoO3lo03Y_O0Gqmlc4CW1MB5N1tfb9QkndWynnl_6yhq0JwcIgpgdetFQZfvvUV__YVWHC3DhS0ltQJaSnlKbCi7X6999b2wXLHsdY_fS4HVvmC7Nlz1Raa4BdFlrFuCkdgD-mJrJ5KCfjKCymGm0wYVQ"