import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles();
  var wantToDelete = props.wantToDelete;

  function handleDeleteClick(){
    props.handleSuccessDelete(props.company, props.created_at);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.handleCancelDelete();
  };

  return (
    <div className={classes.root}>
      <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={wantToDelete}
      onClose={handleClose}
      key={"topcenter"}>
        <Alert onClose={handleClose} severity="error">
        ¿Esta seguro que desea borrar esta tarjeta?
        <br></br>
        <Button onClick={handleDeleteClick} style={{color: "white"}}><DeleteIcon/>Si</Button> 
        <Button onClick={handleClose} style={{color: "white"}}><CloseIcon/>No</Button>
        
        </Alert>
      </Snackbar>
    </div>
  );
}