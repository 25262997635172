import React from "react";

//Social media icons

import FbN from '../../assets/img/FbN.png'
import inN from '../../assets/img/inN.png'
import InsN from '../../assets/img/InsN.png'
import twN from '../../assets/img/twN.png'

import FbB from '../../assets/img/FbB.png'
import inB from '../../assets/img/inB.png'
import InsB from '../../assets/img/InsB.png'
import twB from '../../assets/img/twB.png'

import FbB2 from '../../assets/img/FbB2.png'
import inB2 from '../../assets/img/inB2.png'
import InsB2 from '../../assets/img/InsB2.png'
import twB2 from '../../assets/img/twB2.png'

import FbN2 from '../../assets/img/FbN2.png'
import inN2 from '../../assets/img/inN2.png'
import InsN2 from '../../assets/img/InsN2.png'
import twN2 from '../../assets/img/twN2.png'

import Otro from '@material-ui/icons/Add';
import Amazon from '../../assets/img/amazonicon.png'
import EbayIcon from '../../assets/img/ebayicon.png'
import ShopifyIcon from '../../assets/img/shopifyicon.png'
import MercadolibreIcon from '../../assets/img/mercadolibreicon.png'

import Linkedin from "assets/img/linkedin.png"
import Facebook from "assets/img/facebook.png"
import Instagram from "assets/img/instagram.png"
import Twitter from "assets/img/twitter.png"



export default function SocialMediaBadges(props) {
    let facebook = FbB;
    let linkedin = inB;
    let instagram = InsB;
    let twitter = twB;
    if(props.buttonStyle !== undefined){
        let style = props.buttonStyle
        if(style === "style1"){
            facebook = FbN;
            linkedin = inN;
            instagram = InsN;
            twitter = twN;
        } else if(style === "style2"){
            facebook = FbB;
            linkedin = inB;
            instagram = InsB;
            twitter = twB;
        } else if(style === "style3"){
            facebook = FbN2;
            linkedin = inN2;
            instagram = InsN2;
            twitter = twN2;
        } else if(style === "style4"){
            facebook = FbB2;
            linkedin = inB2;
            instagram = InsB2;
            twitter = twB2;
        }
    }

    const handleSocialMediaCardClick = link => {
        let sublink = link.substring(0,4)
        if(sublink !== "http")
            window.open(`https://${link}`, '_blank');
        else
            window.open(link, '_blank');
    };
    return(
        <div>
            <div style={{marginTop: "15px"}}>
                {/* {props.facebook && 
                    <FacebookIcon id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.facebook)} style={{ color: "#3b5998", margin: "5px"}}/>}
                {props.instagram && 
                    <InstagramIcon id="socialMediaButtons"onClick={() => handleSocialMediaCardClick(props.instagram)} style={{ color: "#bc2a8d", margin: "5px"}}/>}
                {props.twitter && 
                    <TwitterIcon id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.twitter)} style={{ color: "#26a7de", margin: "5px"}}/>}
                {props.linkedin && 
                    <LinkedInIcon id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.linkedin)} style={{ color: "#0072b1", margin: "5px"}}/>}
                {props.otro && 
                    <Otro id="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.otro)} style={{ color: "green", margin: "5px"}}/>} */}
                    {props.facebook && 
                    <img className="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.facebook)} style={{ color: props.fontColor, margin: "5px", cursor: "pointer"}} src={Facebook} alt="facebook"/>}
                {props.instagram && 
                    <img className="socialMediaButtons"onClick={() => handleSocialMediaCardClick(props.instagram)} style={{ color: props.fontColor, margin: "5px", cursor: "pointer"}} src={Instagram} alt="instagram"/>}
                {props.twitter && 
                    <img className="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.twitter)} style={{ color: props.fontColor, margin: "5px", cursor: "pointer"}} src={Twitter} alt="twitter"/>}
                {props.linkedin && 
                    <img className="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.linkedin)} style={{ color: props.fontColor, margin: "5px", cursor: "pointer"}} src={Linkedin} alt="linkedin"/>}
                {props.otro && 
                    <Otro className="socialMediaButtons" onClick={() => handleSocialMediaCardClick(props.otro)} style={{ color: props.fontColor, margin: "5px", cursor: "pointer"}}/>}
            </div>
            <div>
                {props.amazon && props.amazon !== "" &&
                    <img onClick={() => handleSocialMediaCardClick(props.amazon)} id="Rounded" src={Amazon}  width="35px" height="35px" alt="amazon"/>}
                {props.shopify && props.shopify !== "" && 
                    <img onClick={() => handleSocialMediaCardClick(props.shopify)} id="Rounded" src={ShopifyIcon}  width="35px" height="35px" alt="shopify"/>}
                {props.mercadolibre && props.mercadolibre !== "" && 
                    <img onClick={() => handleSocialMediaCardClick(props.mercadolibre)} id="Rounded" src={MercadolibreIcon}  width="35px" height="35px" alt="mercado libre"/>}
                {props.ebay && props.ebay !== "" && 
                    <img onClick={() => handleSocialMediaCardClick(props.ebay)} id="Rounded" src={EbayIcon}  width="35px" height="35px" alt="ebay"/>}
                
            </div>
            
        </div>
    )
}