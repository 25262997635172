
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Hidden} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import { Auth } from "aws-amplify";
import FormErrors from "./FormErrors";
import Validate from "./FormValidation";
import loading from 'assets/img/loading.gif'
import axios from 'axios'


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};


const useStyles = makeStyles(styles);

class LogIn extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {
        cognito: null,
        blankfield: false,
      },
      isLogined: false,
      classes: useStyles,
      status: "",
      passwordReveal: "password"
    };
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      }
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try{
      this.setState({isLogined: true}); //Just for testing purposes
      this.setState({status: "Iniciando Sesion"});
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.props.rest.auth.setUser(user);
      const session = await Auth.currentSession();
      axios.defaults.headers.common['IDToken'] = session.getIdToken().getJwtToken();
      Auth.currentUserPoolUser();

      this.props.rest.auth.setAuthStatus(true);
      await this.props.rest.auth.configureAfterLogin(session, user);
      
    }catch(error){
      this.setState({isLogined: false});
      let err = null;
      !error.message ? err = {"message": error} : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      })
    }
  };

  onInputChange = (e) => {
    let name = e.target.id
    this.setState({[name]: e.target.value})
    
  };

  handleEnterInput = (event) =>{
    if(event.key === 'Enter'){
      this.handleSubmit(event);
    }
  } 

  getTokens = () => {
    try{
      Auth.currentSession().then(res=>{
        let accessToken = res.getAccessToken()
        // eslint-disable-next-line
        let jwt = accessToken.getJwtToken()
  
        //You can print them to see the full objects
        // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
        // console.log(`myJwt: ${jwt}`)
      })
    } catch(error){
      console.log(error)
    }
  };

  handlePasswordReveal = () =>{
    let passwordReveal = this.state.passwordReveal
    if(passwordReveal === "password"){
      passwordReveal = "text"
    } else{
      passwordReveal = "password"
    }
    this.setState({passwordReveal: passwordReveal})
  }

  render() {
    if (this.props.rest.auth.isAuthenticated === true) {
      return <Redirect to='/' />
    }
    if(this.state.isLogined){
      return (
        <center>
            <Hidden mdUp>
              <img src={loading} alt="loading..." width="80%"  height="80%"/>
              <h2>{this.state.status}</h2>
            </Hidden>
            <Hidden mdDown>
              <img src={loading} alt="loading..."/>
              <h1>{this.state.status}</h1>
            </Hidden>
          </center>
      )
    }
    return (
      <div>
          <GridContainer > 
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <CardHeader color="primary">
                <h4 className={this.state.classes.cardTitleWhite}>Log In</h4>
                <p className={this.state.classes.cardCategoryWhite}>Favor de ingresar su Usuario y Contraseña</p>
              </CardHeader>
              <CardBody>
              <FormErrors formerrors={this.state.errors} />
              <form>
                <GridContainer> {/* First Row */}
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      id="username"
                      labelText="Usuario"
                      value={this.state.username}
                      onChange={this.onInputChange}
                      formControlProps={{
                        fullWidth: false
                      }}
                      inputProps={{
                        type: "text",
                        onKeyPress: this.handleEnterInput,
                        onChange: this.onInputChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer> {/* Second Row */}
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      type={this.state.passwordReveal}
                      id="password"
                      labelText="Contraseña"
                      value={this.state.password}
                      onChange={this.onInputChange}
                      formControlProps={{
                        fullWidth: false
                      }}
                      inputProps={{
                        type: this.state.passwordReveal,
                        onKeyPress: this.handleEnterInput,
                        onChange: this.onInputChange
                      }}
                    />
                    <IconButton onClick={this.handlePasswordReveal} size="small" aria-label="edit">
                      <VisibilityIcon style={{ color: "#1da1f2" }} fontSize="small"/>
                    </IconButton>
                  </GridItem>
                </GridContainer>
                </form>
              </CardBody>
              <CardFooter>
                <Button onClick={this.handleSubmit} color="success" >Iniciar Sesión</Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
        </GridContainer>
        
      
    </div>
    );
  }
}

export default LogIn;