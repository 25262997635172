import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import NewCard from "views/Card/CardProduct.js"

import styles from "assets/jss/material-dashboard-react/cardImagesStyles.js";
import config from 'config'
import './Read.css'

//Axios HTTP Methods
import axios from 'axios';

const useStyles = makeStyles(styles);

//AWS S3 bucket config
var AWS = require('aws-sdk');
var s3AWSBucket = new AWS.S3( { params: {Bucket: config.s3.bucketName} } );

export default function AddProducts(props){

    AWS.config.update({ 
        accessKeyId: props.auth.credentials.accessKeyId, 
        secretAccessKey: props.auth.credentials.secretAccessKey, 
        sessionToken: props.auth.credentials.sessionToken,
        region: config.s3.region, 
        dirName: config.s3.dirName });

    s3AWSBucket.config.update({ 
        accessKeyId: props.auth.credentials.accessKeyId, 
        secretAccessKey: props.auth.credentials.secretAccessKey, 
        sessionToken: props.auth.credentials.sessionToken,
        region: config.s3.region, 
        dirName: config.s3.dirName });

    const classes = useStyles;
    // eslint-disable-next-line
    const [product, setProduct] = React.useState(() => { return "Hello Word"});
    var CardsImagesToUpload = 0;
    var CardsImagesUploaded = 0;

    //Set React hooks for each card
    const [cardImage1, setCardImage1] = React.useState(() => { if(props.products.card1 !== undefined){return props.products.card1.cardImage}else return ""});
    const [cardTitle1, setCardTitle1] = React.useState(() => {if(props.products.card1 !== undefined){return  props.products.card1.cardTitle}else return ""});
    const [cardName1, setCardName1] = React.useState(() => {if(props.products.card1 !== undefined){return  props.products.card1.cardName}else return ""});
    const [cardDescription1, setCardDescription1] = React.useState(() => {if(props.products.card1 !== undefined){return  props.products.card1.cardDescription}else return ""});

    const [cardImage2, setCardImage2] = React.useState(() => {if(props.products.card2 !== undefined){return props.products.card2.cardImage}else return ""});
    const [cardTitle2, setCardTitle2] = React.useState(() => {if(props.products.card2 !== undefined){return props.products.card2.cardTitle}else return ""});
    const [cardName2, setCardName2] = React.useState(() => {if(props.products.card2 !== undefined){return props.products.card2.cardName}else return ""});
    const [cardDescription2, setCardDescription2] = React.useState(() => {if(props.products.card2 !== undefined){return props.products.card2.cardDescription}else return ""});

    const [cardImage3, setCardImage3] = React.useState(() => {if(props.products.card3 !== undefined){return props.products.card3.cardImage}else return ""});
    const [cardTitle3, setCardTitle3] = React.useState(() => {if(props.products.card3 !== undefined){return props.products.card3.cardTitle}else return ""});
    const [cardName3, setCardName3] = React.useState(() => {if(props.products.card3 !== undefined){return props.products.card3.cardName}else return ""});
    const [cardDescription3, setCardDescription3] = React.useState(() => {if(props.products.card3 !== undefined){return props.products.card3.cardDescription}else return ""});

    const [cardImage4, setCardImage4] = React.useState(() => {if(props.products.card4 !== undefined){return props.products.card4.cardImage}else return ""});
    const [cardTitle4, setCardTitle4] = React.useState(() => {if(props.products.card4 !== undefined){return props.products.card4.cardTitle}else return ""});
    const [cardName4, setCardName4] = React.useState(() => {if(props.products.card4 !== undefined){return props.products.card4.cardName}else return ""});
    const [cardDescription4, setCardDescription4] = React.useState(() => {if(props.products.card4 !== undefined){return props.products.card4.cardDescription}else return ""});

    const [cardImage5, setCardImage5] = React.useState(() => {if(props.products.card5 !== undefined){return props.products.card5.cardImage}else return ""});
    const [cardTitle5, setCardTitle5] = React.useState(() => {if(props.products.card5 !== undefined){return props.products.card5.cardTitle}else return ""});
    const [cardName5, setCardName5] = React.useState(() => {if(props.products.card5 !== undefined){return props.products.card5.cardName}else return ""});
    const [cardDescription5, setCardDescription5] = React.useState(() => {if(props.products.card5 !== undefined){return props.products.card5.cardDescription}else return ""});

    const [cardImage6, setCardImage6] = React.useState(() => {if(props.products.card6 !== undefined){return props.products.card6.cardImage}else return ""});
    const [cardTitle6, setCardTitle6] = React.useState(() => {if(props.products.card6 !== undefined){return props.products.card6.cardTitle}else return ""});
    const [cardName6, setCardName6] = React.useState(() => {if(props.products.card6 !== undefined){return props.products.card6.cardName}else return ""});
    const [cardDescription6, setCardDescription6] = React.useState(() => {if(props.products.card6 !== undefined){return props.products.card6.cardDescription}else return ""});

    const [cardImage7, setCardImage7] = React.useState(() => {if(props.products.card7 !== undefined){return props.products.card7.cardImage}else return ""});
    const [cardTitle7, setCardTitle7] = React.useState(() => {if(props.products.card7 !== undefined){return props.products.card7.cardTitle}else return ""});
    const [cardName7, setCardName7] = React.useState(() => {if(props.products.card7 !== undefined){return props.products.card7.cardName}else return ""});
    const [cardDescription7, setCardDescription7] = React.useState(() => {if(props.products.card7 !== undefined){return props.products.card7.cardDescription}else return ""});

    const [cardImage8, setCardImage8] = React.useState(() => {if(props.products.card8 !== undefined){return props.products.card8.cardImage}else return ""});
    const [cardTitle8, setCardTitle8] = React.useState(() => {if(props.products.card8 !== undefined){return props.products.card8.cardTitle}else return ""});
    const [cardName8, setCardName8] = React.useState(() => {if(props.products.card8 !== undefined){return props.products.card8.cardName}else return ""});
    const [cardDescription8, setCardDescription8] = React.useState(() => {if(props.products.card8 !== undefined){return props.products.card8.cardDescription}else return ""});

    const [cardImage9, setCardImage9] = React.useState(() => {if(props.products.card9 !== undefined){return props.products.card9.cardImage}else return ""});
    const [cardTitle9, setCardTitle9] = React.useState(() => {if(props.products.card9 !== undefined){return props.products.card9.cardTitle}else return ""});
    const [cardName9, setCardName9] = React.useState(() => {if(props.products.card9 !== undefined){return props.products.card9.cardName}else return ""});
    const [cardDescription9, setCardDescription9] = React.useState(() => {if(props.products.card9 !== undefined){return props.products.card9.cardDescription}else return ""});

    const [cardImage10, setCardImage10] = React.useState(() => {if(props.products.card10 !== undefined){return props.products.card10.cardImage}else return ""});
    const [cardTitle10, setCardTitle10] = React.useState(() => {if(props.products.card10 !== undefined){return props.products.card10.cardTitle}else return ""});
    const [cardName10, setCardName10] = React.useState(() => {if(props.products.card10 !== undefined){return props.products.card10.cardName}else return ""});
    const [cardDescription10, setCardDescription10] = React.useState(() => {if(props.products.card10 !== undefined){return props.products.card10.cardDescription}else return ""});

    async function handleHowManyCardsUsed(cb){
        let ProductCards = {};
        let imageType = "";
        let imagePath = ""
        CardsImagesToUpload += 1
        CardsImagesToUpload += 1
        CardsImagesToUpload += 1
        var pathvars = {
            company: props.company,
            agent_name: props.agent_name,
            agent_id: props.agent_id,
            card: "cardNumber"
        }
        if(cardTitle1 !== ""){
            var card1 = {
                cardTitle: cardTitle1,
                cardName: cardName1,
                cardImage: cardImage1,
                cardDescription: cardDescription1
            }

            ProductCards.card1 = card1;
            let pathvars1 = pathvars
            pathvars1.card = "card1";
            handleImageFileUpload_S3_Image1(card1.cardImage, pathvars1, (result) => {
                CardsImagesUploaded += 1;
                ProductCards.card1.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card1.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card1.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card1.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }


        }
        if(cardTitle2 !== ""){
            var card2 = {
                cardTitle: cardTitle2,
                cardName: cardName2,
                cardImage: cardImage2,
                cardDescription: cardDescription2
            }
            ProductCards.card2 = card2;
            let pathvars2 = pathvars
            pathvars2.card = "card2";

            handleImageFileUpload_S3_Image2(card2.cardImage, pathvars2, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card2.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card2.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card2.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card2.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }
        }
        if(cardTitle3 !== ""){
            var card3 = {
                cardTitle: cardTitle3,
                cardName: cardName3,
                cardImage: cardImage3,
                cardDescription: cardDescription3
            }
            ProductCards.card3 = card3;
            let pathvars3 = pathvars;
            pathvars3.card = "card3";
            handleImageFileUpload_S3_Image3(card3.cardImage, pathvars3, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card3.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card3.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card3.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card3.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle4 !== ""){
            var card4 = {
                cardTitle: cardTitle4,
                cardName: cardName4,
                cardImage: cardImage4,
                cardDescription: cardDescription4
            }
            ProductCards.card4 = card4;
            let pathvars4 = pathvars;
            pathvars4.card = "card4";
            handleImageFileUpload_S3_Image4(card4.cardImage, pathvars4, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card4.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card4.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card4.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card4.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle5 !== ""){
            var card5 = {
                cardTitle: cardTitle5,
                cardName: cardName5,
                cardImage: cardImage5,
                cardDescription: cardDescription5
            }
            ProductCards.card5 = card5;
            let pathvars5 = pathvars;
            pathvars5.card = "card5";
            handleImageFileUpload_S3_Image5(card5.cardImage, pathvars5, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card5.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card5.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card5.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card5.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle6 !== ""){
            var card6 = {
                cardTitle: cardTitle6,
                cardName: cardName6,
                cardImage: cardImage6,
                cardDescription: cardDescription6
            }
            ProductCards.card6 = card6;
            let pathvars6 = pathvars;
            pathvars6.card = "card6";
            handleImageFileUpload_S3_Image6(card6.cardImage, pathvars6, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card6.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card6.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card6.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card6.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle7 !== ""){
            var card7 = {
                cardTitle: cardTitle7,
                cardName: cardName7,
                cardImage: cardImage7,
                cardDescription: cardDescription7
            }
            ProductCards.card7 = card7;
            let pathvars7 = pathvars;
            pathvars7.card = "card7";
            handleImageFileUpload_S3_Image7(card7.cardImage, pathvars7, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card7.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card7.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card7.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card7.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle8 !== ""){
            var card8 = {
                cardTitle: cardTitle8,
                cardName: cardName8,
                cardImage: cardImage8,
                cardDescription: cardDescription8
            }
            ProductCards.card8 = card8;
            let pathvars8 = pathvars;
            pathvars8.card = "card8";
            handleImageFileUpload_S3_Image8(card8.cardImage, pathvars8, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card8.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card8.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card8.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card8.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle9 !== ""){
            var card9 = {
                cardTitle: cardTitle9,
                cardName: cardName9,
                cardImage: cardImage9,
                cardDescription: cardDescription9
            }
            ProductCards.card9 = card9;
            let pathvars9 = pathvars;
            pathvars9.card = "card9";
            handleImageFileUpload_S3_Image9(card9.cardImage, pathvars9, (result) => {
                // console.log("UPLOADED TO PATH: ", result)
                CardsImagesUploaded += 1;
                ProductCards.card9.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card9.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card9.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card9.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }

        }
        if(cardTitle10 !== ""){
            var card10 = {
                cardTitle: cardTitle10,
                cardName: cardName10,
                cardImage: cardImage10,
                cardDescription: cardDescription10
            }
            ProductCards.card10 = card10;
            let pathvars10 = pathvars;
            pathvars10.card = "card10";

            

            handleImageFileUpload_S3_Image10(card10.cardImage, pathvars10, (result) => {
                // console.log("UPLOADED TO PATH: ", result);
                CardsImagesUploaded += 1;
                ProductCards.card10.cardImage = decodeURIComponent(result)
                if(CardsImagesToUpload === CardsImagesUploaded){
                    CardsImagesToUpload += 1
                    submitHandler(props.company, props.agent_id, props.content, ProductCards, props);
                }
            });

            if (typeof ProductCards.card10.cardImage !== 'string'){
                CardsImagesToUpload += 1
                imageType = ProductCards.card10.cardImage.type.toString();
                imageType = imageType.substring(6,imageType.length);
                imagePath = `${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/${pathvars.card}.${imageType}`

                ProductCards.card10.cardImage = imagePath
            } else{
                CardsImagesUploaded -= 1;
            }
        }
        return cb(ProductCards);
    }

    const handleSaveCards= (e) =>{
        props.handleLoadingGif(true, "Guardando Productos");
        handleHowManyCardsUsed((result) => {
            submitHandler(props.company, props.agent_id, props.content, result, props);
        });
    }

    return(
        <div>
            <GridContainer > 
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardHeader color="primary">
                    <h2>{props.agent_name}</h2>
                    <h4 className={classes}>Agregar Productos y Servicios</h4>
                    <p>Añade tus productos o servicios junto con su respectiva imagen. Si la tarjeta no contiene un titulo, no aparecera en sus productos</p>
                </CardHeader>
                <CardBody>
                <GridContainer xs={12} sm={12} md={12}> 
                    <GridItem xs={12} sm={12} md={11}>
                        <Button color="success" onClick={handleSaveCards}>Guardar Todo</Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                        <Button color="danger" onClick={props.handleCancelAddProducts} style={{position: 'relative', floatRight: "0px"}}>Cancelar</Button>
                    </GridItem>
                    <div className='rows'>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle1}
                            setCardName={setCardName1}
                            setCardDescription={setCardDescription1}
                            cardTitle={cardTitle1}
                            cardName={cardName1}
                            cardDescription={cardDescription1}
                            setCardImage={setCardImage1}
                            cardImage={cardImage1}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle2}
                            setCardName={setCardName2}
                            setCardDescription={setCardDescription2}
                            cardTitle={cardTitle2}
                            cardName={cardName2}
                            cardDescription={cardDescription2}
                            setCardImage={setCardImage2}
                            cardImage={cardImage2}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle3}
                            setCardName={setCardName3}
                            setCardDescription={setCardDescription3}
                            cardTitle={cardTitle3}
                            cardName={cardName3}
                            cardDescription={cardDescription3}
                            setCardImage={setCardImage3}
                            cardImage={cardImage3}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle4}
                            setCardName={setCardName4}
                            setCardDescription={setCardDescription4}
                            cardTitle={cardTitle4}
                            cardName={cardName4}
                            cardDescription={cardDescription4}
                            setCardImage={setCardImage4}
                            cardImage={cardImage4}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle5}
                            setCardName={setCardName5}
                            setCardDescription={setCardDescription5}
                            cardTitle={cardTitle5}
                            cardName={cardName5}
                            cardDescription={cardDescription5}
                            setCardImage={setCardImage5}
                            cardImage={cardImage5}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle6}
                            setCardName={setCardName6}
                            setCardDescription={setCardDescription6}
                            cardTitle={cardTitle6}
                            cardName={cardName6}
                            cardDescription={cardDescription6}
                            setCardImage={setCardImage6}
                            cardImage={cardImage6}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle7}
                            setCardName={setCardName7}
                            setCardDescription={setCardDescription7}
                            cardTitle={cardTitle7}
                            cardName={cardName7}
                            cardDescription={cardDescription7}
                            setCardImage={setCardImage7}
                            cardImage={cardImage7}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle8}
                            setCardName={setCardName8}
                            setCardDescription={setCardDescription8}
                            cardTitle={cardTitle8}
                            cardName={cardName8}
                            cardDescription={cardDescription8}
                            setCardImage={setCardImage8}
                            cardImage={cardImage8}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle9}
                            setCardName={setCardName9}
                            setCardDescription={setCardDescription9}
                            cardTitle={cardTitle9}
                            cardName={cardName9}
                            cardDescription={cardDescription9}
                            setCardImage={setCardImage9}
                            cardImage={cardImage9}
                            />
                        </div>
                        <div className='row'>
                            <NewCard
                            props={product}
                            setCardTitle={setCardTitle10}
                            setCardName={setCardName10}
                            setCardDescription={setCardDescription10}
                            cardTitle={cardTitle10}
                            cardName={cardName10}
                            cardDescription={cardDescription10}
                            setCardImage={setCardImage10}
                            cardImage={cardImage10}
                            />
                        </div>
                    </div>
                </GridContainer>
                </CardBody>
                <CardFooter>
                <GridItem xs={12} sm={12} md={11}>
                        <Button color="success" onClick={handleSaveCards}>Guardar Todo</Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                        <Button color="danger" onClick={props.handleCancelAddProducts} style={{position: 'relative', floatRight: "0px"}}>Cancelar</Button>
                    </GridItem>
                </CardFooter>
                </Card>
            </GridItem>
            </GridContainer>
            
        </div>
    )
}

async function handleImageFileUpload_S3_Image1(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card1.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card1.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card1.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image2(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }
    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card2.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card2.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card2.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image3(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card3.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card3.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card3.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image4(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card4.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card4.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card4.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image5(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card5.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card5.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card5.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image6(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card6.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card6.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card6.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image7(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card7.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card7.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card7.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image8(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card8.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card8.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card8.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image9(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card9.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card9.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card9.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}

async function handleImageFileUpload_S3_Image10(FileImage, pathvars, cb) {
    //If FileImage is not a file we end upload.
    
    if (typeof FileImage === 'string'){
        return cb(FileImage);
    }

    let linkOfImage = getBase64(FileImage, (result) => {
        let 
        base64StringImage = result;

        var imageType = base64StringImage.substring(11,14);
    
        if(imageType === "png"){
        imageType = "png"
        } else if(imageType === "gif") {
            imageType = "gif"
        } else if(imageType === "jpg") {
            imageType = "jpg"
        } else {
        imageType = "jpeg"
        }
        let buf = Buffer.from(base64StringImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
        var data = {
        Key: `static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card10.${imageType}`, 
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: `image/${imageType}`
        };
        s3AWSBucket.putObject(data, function(err, data){
            if (err) { 
            console.log(err);
            console.log('Hubo un Error al subir la imagen: ', data);
            } else {
            console.log('Imagen subida con exito.');
                cb(encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card10.${imageType}`));
            }
            
        });
        return encodeURIComponent(`${config.app.static_url}/static/media/users/${pathvars.company}_${pathvars.agent_name}_${pathvars.agent_id}/card10.${imageType}`)
    });
    Promise.all([linkOfImage]).then((values) => {
        return linkOfImage
    });
}


function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

function submitHandler(company, agent_id, content, products, props){
    let productsAsString = JSON.stringify(products);

    // Setting object values to send in request
      let object = {
          body: {
            company: company,
            agent_id: agent_id,
            content: content,
            products: productsAsString
          }
      }

    // PUT Data
      axios.put(config.api.invokeUrl, object)
      .then(response => {
        // On success response: logs response and validates response message
        let statusCode = response.data.statusCode;
        console.log(response.data);
        if (statusCode !== 400){
            props.handleLoadingGif(false, "");
            props.handleSuccessAddProducts();
        }else{
          if(response.data.message === 'The ID Token does not containt user_id'){

          }else if(response.data.message === 'The ID Token does not containt user_id' || response.data.message === 'Cannot create note with empty content.'){

          }
          else{

          }
          
        }
        
      })
      .catch(error =>{
        // On error logs error and notify the user
        console.log(error);
      })
  };